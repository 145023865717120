import React from "react";
import ReactPaginate from "react-paginate";
import { GrFormNext, GrFormPrevious, GrMore } from "react-icons/gr";

export default function PaginateComponent({ pageCount, setCurrentPage }) {
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <nav>
        <ReactPaginate
          previousLabel={<GrFormPrevious />}
          nextLabel={<GrFormNext />}
          breakLabel={<GrMore />}
          breakClassName={"page-link"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          pageLinkClassName={"page-link"}
          containerClassName={"pagination"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          disabledClassName={"page-item disabled"}
          activeClassName={"page-item active"}
        />
      </nav>
    </>
  );
}
