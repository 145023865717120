import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { asingProtocolToSite } from "../../Conections/Sites";
import { useCDI } from "../../Context/CDIContext";
import "../../Styles/ModalCDI.css";

export default function AddProtocol({
    user,
    modalClose,
    updateTable,
    updateTotal,
    cdi
}) {
    const [protocolValue, setProtocolValue] = useState({});
    const [allProtocols, setAllProtocols] = useState([]);
    const [protocols, setProtocols] = useState(user?.protocol);
    const { listProtocols } = useCDI();

    const handleChange = (event) => {
        let input = event.target.value;
        let sep = input.split("~~");
        let n_protocol = sep[0];
        let name = sep[1];

        setProtocolValue({
            ...protocolValue,
            n_protocol,
            name,
        });
    };

    const asignarProtocol = (event) => {
        event.preventDefault();

        const { id, token } = user;

        Swal.fire({
            title: "",
            allowEscapeKey: false,
            allowOutsideClick: false,
            background: "transparent",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        asingProtocolToSite({ token, id }, protocolValue.n_protocol)
            .then((resp) => {
                setProtocols(resp?.user?.protocol);
                Swal.close();
                Swal.fire({
                    title: "Exito!",
                    text: "Se asignó exitosamente",
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  }).then(() => {
                    modalClose();
                    updateTable();
                    updateTotal()
                  });
            })
            .catch((err) => {
                Swal.close();
            });
    };

    useEffect(() => {
        setProtocols(user?.protocol);
    }, [user]);

    useEffect(() => {
        listProtocols(setAllProtocols);
    }, []);

    const protocolList = () => {
      const res = [];
      for (const item of allProtocols) {
       if (!cdi?.protocol.map(item => item.number).includes(item.number)) res.push(item)
      }
      return res
    }

    return (
    <div className="Modal">
      <div className="Modal_AddProtocols">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Agregar Protocolo
              </h5>
              <button
                onClick={modalClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <>
                {allProtocols.length === 0 ? (
                    <div className="ml-3">no hay protocolos activos</div>
                ) : (
                    <>
                    <Form.Group as={Col} controlId="slectProtocol">
                        <Form.Control as="select" onChange={handleChange}>
                        <option value="">Seleccionar Existente</option>
                        {allProtocols?.map((prot, i) => {
                            if (!cdi?.protocol.map(item => item.number).includes(prot.number)) {
                                return (
                                <option key={i} value={prot.n_protocol + "~~" + prot.name}>
                                    {prot.name} ({prot.number})
                                </option>
                                );
                            }
                        })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Button onClick={asignarProtocol}>Agregar</Button>
                    </Form.Group>
                    </>
                )}
              </>
            </div>
          </div>
      </div>
    </div>
 );
}
