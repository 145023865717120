import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

export default function RegistAdmin() {
  const [validated, setValidated] = useState(false);
  const [inputRegist, setInputRegist] = useState({
    name: "",
    email: "",
    surname: "",
    phone: "",
    address: "",
    password: "",
  });

  const { handleRegistAdmin } = useAuth();

  let history = useHistory();

  const redirect = () => {
    history.push("/login");
  };

  useEffect(() => {}, [inputRegist]);

  const handleChange = (event) => {
    setInputRegist({
      ...inputRegist,
      [event.target.name]: event.target.value,
    });
  };

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (inputRegist.email === "" || inputRegist.password === "") {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (inputRegist.password.length < 6) {
      setValidated(true);
      event.preventDefault();
      return;
    }

    setValidated(true);
    if (form.checkValidity()) {
      event.preventDefault();

      handleRegistAdmin(inputRegist);
    }
  }
  return (
    <>
      <Container className="blur-l">
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col xs={10}>
            <div className="card-login o-hidden border-0 my-5">
              <div className="card-body-login p-0">
                <Row>
                  <Col lg={6} className="d-lg-block bg-login-image"></Col>
                  <Col lg={6}>
                    <div className="px-5 pt-5 pb-3">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          ¡Bienvenido a <b>FollowMed!</b>
                        </h1>
                      </div>
                      <div>
                        <Form
                          noValidate
                          validated={validated}
                          className="h-100"
                          onSubmit={handleSubmit}
                        >
                          <Form.Group
                            className="mt-2"
                            controlId="validationCustom01"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Nombre"
                              name="name"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="my-3"
                            controlId="validationCustom02"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Apellido"
                              name="surname"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="my-3"
                            controlId="validationCustom02"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              name="email"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="my-3"
                            controlId="validationCustom02"
                          >
                            <Form.Control
                              type="text"
                              placeholder="telefono"
                              name="phone"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="my-3"
                            controlId="validationCustom02"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Dirección/Domicilio"
                              name="address"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="my-3"
                            controlId="validationPassword"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Contraseña"
                              name="password"
                              onChange={handleChange}
                              required
                              className="inputCommon"
                            />
                            <Form.Control.Feedback>
                              Se ve bien!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              Por Favor complete el campo correctamente
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="d-flex justify-content-around align-items-center">
                            <Button
                              className="mt-4 mb-3 w-30-res-reg"
                              type="submit"
                              variant="outline-primary"
                            >
                              Registrarse
                            </Button>
                          </Form.Group>

                          <Link to="/login">Ya tengo cuenta!</Link>
                        </Form>
                      </div>
                      <hr />
                      <div></div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
