import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
	asingProtocolToSite,
	createNewProtocol,
} from '../../../Conections/Sites';
import { useCDI } from '../../../Context/CDIContext';
export default function ProtocolForm({ type }) {
	const [validated, setValidated] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [inputValue, setInputValue] = useState({
		technicalName: '',
		name: '',
		number: '',
		visits: 0,
		duration: [
			{
				name: '',
				frequency: '',
			},
		],
	});

	const handleChange = (event) => {
		setInputValue((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const handleChangeSelect = (event, info, index) => {
		setInputValue((state) => {
			const duration = state.duration.map((dur) => ({ ...dur }));
			duration[index] = {
				name: index.toString(),
				frequency: event.target.value,
			};
			return {
				...state,
				duration,
			};
		});
	};

	useEffect(() => {
		setConfirm(true);
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		const re = /^[a-zA-Z0-9_-]*$/;
		if (re.test(inputValue.number) === false) {
			Swal.fire({
				title: 'N° de protocolo inválido',
				text: 'Solo puede contener números, letras y guiones',
				icon: 'error',
				confirmButtonText: 'Cerrar',
			});
			return;
		}
		const { token, id } = JSON.parse(localStorage.getItem('step_med_user'));
		const payload = {
			...inputValue,
			duration: inputValue.duration.slice(0, inputValue.visits),
		};
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		createNewProtocol(payload, type, token).then((resp) => {
			if (type === 'cdi' && resp.success) {
				asingProtocolToSite({ token, id }, resp.protocol.id).then(() => {
					Swal.close();
				});
			} else Swal.close();
			if (resp.success) {
				Swal.fire({
					title: 'Exito!',
					text: 'Se creó exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				}).then(() => {
					window.location.reload();
				});
			} else {
				Swal.fire({
					title: 'Oh No!',
					text: resp.message,
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			}
		});
	};

	const numberVisitis = parseInt(inputValue?.visits);
	const vistisArray = numberVisitis ? [...Array(numberVisitis).keys()] : [];
	return (
		<Form
			noValidate
			validated={validated}
			className='my-5'
			onSubmit={handleSubmit}
		>
			<Form.Row className='justify-content-center formTables shadow'>
				<Col className='mb-2 pl-5' xs={12}>
					Información del Protocolo
				</Col>
				<Form.Group as={Col} md='5' controlId='protocol_home'>
					<Form.Control
						type='text'
						placeholder='Nombre de Protocolo'
						name='technicalName'
						onChange={handleChange}
					/>
					<Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} md='5' controlId='protocol_home'>
					<Form.Control
						type='text'
						placeholder='Acrónimo'
						name='name'
						onChange={handleChange}
					/>
					<Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
				</Form.Group>

				<Form.Group as={Col} md='5' controlId='protocol_n'>
					<Form.Control
						type='text'
						pattern='[a-zA-Z0-9_-]'
						placeholder='N° de Protocolo'
						name='number'
						onChange={handleChange}
					/>
					<Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
				</Form.Group>

				<Form.Group as={Col} md='5' controlId='visit_time'>
					<InputGroup>
						<Form.Control
							type='number'
							placeholder='N° de visitas'
							aria-describedby='inputGroupPrepend'
							name='visits'
							onChange={handleChange}
						/>
					</InputGroup>
				</Form.Group>
			</Form.Row>
			{vistisArray.map((_, index) => {
				const info = inputValue?.duration[index] || {
					name: index,
					frequency: '',
				};
				return (
					<Form.Row className='justify-content-center'>
						<Form.Group as={Col} md='4'>
							<Form.Label>Frecuencia de visitas</Form.Label>
							<Form.Control
								name={'frequency'}
								onChange={(event) => handleChangeSelect(event, info, index)}
								type='number'
								placeholder='Seleccione dias'
							/>
						</Form.Group>
					</Form.Row>
				);
			})}
			{confirm ? (
				<Row className='justify-content-center mt-3'>
					<Col xs='6' className='d-flex justify-content-center'>
						<button type='submit' className='btn-padding w-50 btn-darkBlue'>
							Enviar
						</button>
					</Col>
				</Row>
			) : null}
		</Form>
	);
}
