import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import {
  assignProtocolByPatient,
  editPatient,
  getProtocols,
} from "../../Conections/Sites";
import { Col, Form, Row, Button } from "react-bootstrap";
import "../../Styles/ModalCDI.css";
import "../../Styles/CommonStyles.css";
import "../../Styles/PacientesComponent.css";
import Swal from "sweetalert2";
import { phoneAreas } from "../../Helpers/PhoneAreas";
import moment from "moment";

export default function ModalPatientsData({
  totalInfo,
  handleEditPatientClose,
  listPatient,
}) {
  const [input, setInput] = useState({
    email: totalInfo.email,
    id: totalInfo.id,
  });
  const [protocolsList, setProtocolsList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    getProtocols({ token: currentUser.token, id: currentUser.id }).then(
      (res) => {
        res.success ? setProtocolsList(res.response) : setProtocolsList([]);
      }
    );
  }, []);

  const handleChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
    if (event.target.name === "firstVisit") {
      const initialVisit = new moment(event.target.value).valueOf();
      setInput({
        ...input,
        [event.target.name]: initialVisit,
      });
    }
  };

  const submitEditPatient = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (input.protocol) {
      assignProtocolByPatient(totalInfo.id, currentUser.token, input.protocol);
    }
    editPatient(input, currentUser.token)
      .then((resp) => {
        Swal.close();
        if (resp.success) {
          Swal.fire({
            title: "Exito!",
            text: "Se editó exitosamente",
            icon: "success",
            confirmButtonText: "Cerrar",
          }).then(() => {
            handleEditPatientClose();
            listPatient();
          });
        } else {
          Swal.close();

          Swal.fire({
            title: "Oh No!",
            text: "Ah ocurrido un error",
            icon: "error",
            confirmButtonText: "Cerrar",
          });
        }
      })
      .catch((err) => {
        Swal.close();

        Swal.fire({
          title: "Oh No!",
          text: "Ah ocurrido un error",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      });
  };
  return (
    <div className='Modal'>
      <div className='Modal__Container-patient'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Editar datos del paciente
              </h5>
              <button
                onClick={handleEditPatientClose}
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <Form onSubmit={submitEditPatient}>
              <Row className='pt-4 px-5'>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Nombre:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.name || "-"}
                      onChange={handleChange}
                      name='name'
                    />
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Apellido:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.surname || "-"}
                      onChange={handleChange}
                      name='surname'
                    />
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Dirección:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.address || "-"}
                      onChange={handleChange}
                      name='address'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='pt-4 px-5'>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Email:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.email || "-"}
                      onChange={handleChange}
                      name='email'
                    />
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Email de Familiar</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo?.family_email || "-"}
                      onChange={handleChange}
                      name='family_email'
                    />
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Dni:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.dni || "-"}
                      onChange={handleChange}
                      name='dni'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='pt-4 px-5'>
                <Col xs={3}>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>N° de paciente:</b>
                    </label>
                    <input
                      className='patient-data-container w-100'
                      defaultValue={totalInfo.number || "-"}
                      onChange={handleChange}
                      name='number'
                    />
                  </div>
                </Col>
                <Col xs={3}>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Código pais:</b>
                    </label>
                    <select
                      className='patient-data-container w-100'
                      defaultValue={totalInfo?.phone_area || "-"}
                      onChange={handleChange}
                      name='phone_area'
                    >
                      <option value={totalInfo?.phone_area}>
                        (+{totalInfo?.phone_area})
                      </option>
                      {phoneAreas?.map((area) => (
                        <option value={area.code}>{area.country}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Telefono:</b>
                    </label>
                    <input
                      className='patient-data-container col-12'
                      defaultValue={totalInfo.phone || "-"}
                      onChange={handleChange}
                      name='phone'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='py-4 px-5'>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Asignar Protocolo</b>
                    </label>
                    <select
                      className='patient-data-container w-100'
                      onChange={handleChange}
                      name='protocol'
                      disabled={totalInfo.protocol?.name ? true : false}
                    >
                      <option defaultValue value={"no"}>
                        {totalInfo.protocol?.name}
                      </option>
                      {protocolsList?.map((p) => (
                        <option value={p.id}>{p.name}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className='d-flex justify-content-center flex-column align-items-center'>
                    <label className='align-self-baseline'>
                      <b>Visita cero:</b>
                    </label>
                    <input
                      type='date'
                      className='patient-data-container w-100'
                      onChange={handleChange}
                      name='firstVisit'
                      defaultValue={
                        totalInfo.firstVisit
                          ? moment(totalInfo?.firstVisit).format("YYYY-MM-DD")
                          : null
                      }
                      disabled={
                        !totalInfo.protocol ||
                        totalInfo?.firstVisit < new Date().valueOf()
                          ? true
                          : false
                      }
                    />
                  </div>
                </Col>
              </Row>
              <div className='w-100 d-flex justify-content-center'>
                <Button
                  variant='outline-success w-25 btn-padding'
                  type='submit'
                >
                  Guardar cambios
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
