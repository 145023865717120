import { React, useState } from 'react';
import { Row, Table, Button } from 'react-bootstrap';
import '../../Styles/CommonStyles.css';
import { FaSortDown, FaSortUp, FaCircle, FaInfoCircle } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import ReactTooltip from 'react-tooltip';

export default function PacienteTable({ patientsList, loadingInfo }) {
	const [orderPaciente, setOrderPaciente] = useState(true);
	const upDown = () => {
		orderPaciente ? setOrderPaciente(false) : setOrderPaciente(true);
	};
	return (
		<>
			<Row className='justify-content-around mt-1 p-3'>
				<div className='headerTable d-flex justify-content-between'>
					<p>Lista de Pacientes</p>
					<p className='h5'>
						<FaInfoCircle data-tip data-for='infoTip' />
						<ReactTooltip id='infoTip' place='bottom' effect='solid'>
							<li className='list-unstyled'>
								<IconContext.Provider value={{ color: 'cyan' }}>
									<FaCircle /> Paciente con datos incompletos
								</IconContext.Provider>
							</li>
							<li className='list-unstyled'>
								<IconContext.Provider value={{ color: 'green' }}>
									<FaCircle /> Paciente sin problema
								</IconContext.Provider>
							</li>
							<li className='list-unstyled'>
								<IconContext.Provider value={{ color: 'yellow' }}>
									<FaCircle /> Paciente con problemas leves
								</IconContext.Provider>
							</li>
							<li className='list-unstyled'>
								<IconContext.Provider value={{ color: 'red' }}>
									<FaCircle /> Paciente con problemas graves
								</IconContext.Provider>
							</li>
						</ReactTooltip>
					</p>
				</div>
				{loadingInfo && <div className='text-center'>Cargando...</div>}
				<div className='table-responsive customTables'>
					<Table hover className='table-bordered m-0' size='sm'>
						<thead>
							<tr>
								<th className='text-center'>Numero de Paciente</th>
								<th className='text-center'>Celular</th>
								<th className='text-center'>Protocolo</th>
								<th className='text-center'>Status</th>
							</tr>
						</thead>
						<tbody>
							{patientsList &&
								patientsList.map((info, i) => (
									<tr key={i}>
										<td className='text-center'>{info.number}</td>
										<td className='text-center'>
											(+{info?.phone_area}) {info.phone}
										</td>
										<td className='text-center'>
											{info.protocol
												? info.protocol?.name + ' - ' + info.protocol?.number
												: 'Sin Protocolo'}
										</td>
										<td className='text-center'>
											<IconContext.Provider value={{ color: info.status }}>
												<FaCircle />
											</IconContext.Provider>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</Row>
		</>
	);
}
