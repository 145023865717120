import React, { useState } from "react";
import PaginateComponent from "../../Helpers/PaginateComponent";
import EventosCDITable from "./EventosCDITable";

export default function PaginateProtocols({
  info,
  loadingInfo,
  setInfo,
  mensaje,
}) {

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(info?.length / PER_PAGE);
  const currentPageData = info?.slice(offset, offset + PER_PAGE);

  return (
    <>
      <EventosCDITable
        info={currentPageData}
        loadingInfo={loadingInfo}
        mensaje={mensaje}
      />

      <PaginateComponent
        pageCount={pageCount}
        currentPageData={currentPageData}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
