import { React, useRef } from "react";
import { Col, Row, Table, Button, InputGroup } from "react-bootstrap";
import {
  FaSistrix,
  FaPlusCircle,
  FaSortDown,
  FaSortUp,
  FaRegTrashAlt,
} from "react-icons/fa";
import { BiSpreadsheet } from "react-icons/bi";
import "../../../Styles/CommonStyles.css";
import PointDown from "./PointDown";
import { deleteUser } from "../../../Conections/Admin";
import Swal from "sweetalert2";

export default function CDITables({
  info,
  setOpenModalCDI,
  setOpenModalCDIDetails,
  setDetails,
  loadingInfo,
  upDown,
  orderInstitucion,
  search,
  mensaje,
}) {
  const myRef = useRef(null);

  const handleOpenModal = (infoDetail) => {
    setDetails(infoDetail);
    setOpenModalCDIDetails(true);
  };

  const confimrDelete = (info) => {
    Swal.fire({
      title: "¿Eliminar Centro de Investigación?",
      text: `Se eliminará el centro de investigación ${info.name_site}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(info.id, 'cdis').then((res) => {
          if (res.success) {
            window.location.reload();
            Swal.fire({
              title: "Exito!",
              text: "Se eliminó exitosamente",
              icon: "success",
              confirmButtonText: "Cerrar",
            });
          } else {
            Swal.fire({
              title: "Oh No!",
              text: res.message,
              icon: "error",
              confirmButtonText: "Cerrar",
            });
          }
        });
      }
    });
  };

  return (
    <>
      <Row className='text-center divider mb-5'>
        <Col className='mb-2 '>
          <h2 ref={myRef}>Centros de investigación</h2>
        </Col>
      </Row>
      <div className='mt-5 w-100 d-flex in-but-pos-res'>
        <div className='inicio-btn-site w-40-res'>
          <div className='input-group'>
            <InputGroup>
              <input
                type='text'
                className='form-control '
                placeholder='Buscar...'
                onChange={search}
              />
              <InputGroup.Text>
                <FaSistrix />
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        <div className='new-protocol-res inicio-btn-site m-res-900'>
          <button
            className='text-center btn-padding w-100 btn-darkBlue'
            onClick={() => setOpenModalCDI("site")}
          >
            Nuevo Site <FaPlusCircle />
          </button>
        </div>
      </div>
      <Row className='justify-content-around mt-1 p-3 headerRow '>
        <div className='headerTable'>Lista de Sites </div>
        {loadingInfo && <div className='text-center'>Cargando...</div>}
        {mensaje && <div className='text-center'>No hay resultados</div>}
        <div className='table-responsive customTables'>
          <Table hover className='table-bordered m-0' size='sm'>
            <thead>
              <tr>
                <th className='text-center'>Dirección</th>
                <th className='text-center'>E-Mail</th>
                <th className='text-center'>Teléfono</th>
                <th className='text-center'>N Pacientes</th>
                <th className='text-center'>Detalle</th>
              </tr>
            </thead>
            <tbody>
              {info &&
                info.map((info, i) => (
                  <tr key={i}>
                    <td className='text-center mx-3'>{info.address_site}</td>
                    <td className='text-center mx-3'>{info.email}</td>
                    <td className='text-center mx-3'>{info.phone_site}</td>
                    <td className='text-center mx-3'>{info.n_patients}</td>
                    <td className='text-center mx-3'>
                      <button
                        className='text-center btnTable btn-block btn text-secondary'
                        onClick={() => handleOpenModal(info)}
                      >
                        <BiSpreadsheet />
                      </button>
                    </td>
                    <td className='text-center mx-3'>
                      <FaRegTrashAlt
                        className='delet text-secondary'
                        onClick={() => confimrDelete(info)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Row>
      <PointDown reff={myRef} />
    </>
  );
}
