import React, { useState } from "react";
import ModalProtocol from "../ModalProtocol";
import ModalNewUser from "../../Users/ModalNewUser";
import ModalCDIDetails from "../ModalCDIDetails";
import MyModal from "../../MyModal";
import PaginateCDI from "./PaginateCDI";
import PaginateProtocols from "./PaginateProtocols";
import { Container } from "react-bootstrap";
import { useCDI } from "../../../Context/CDIContext";

export default function HomeBody() {
  const [openModalCDI, setOpenModalCDI] = useState(false);
  const [openModalCDIDetails, setOpenModalCDIDetails] = useState(false);
  const [openModalProtocol, setOpenModalProtocol] = useState(false);
  const [details, setDetails] = useState({});
  const [protocols, setProtocols] = useState([]);
  const [newUserTipe, setNewUserTipe] = useState("site");
  const { getCDIs, loadingInfo } = useCDI();
  const [allArray, setAllArray] = useState([]);
  const [info, setInfo] = useState([]);

  const openModal = (type) => {
    setOpenModalCDI(true);
    setNewUserTipe(type);
  };

  return (
    <Container className='cdi-margin'>
      <PaginateCDI
        openModal={openModal}
        setOpenModalCDIDetails={setOpenModalCDIDetails}
        setDetails={setDetails}
        loadingInfo={loadingInfo}
        getCDIs={getCDIs}
        allArray={allArray}
        setAllArray={setAllArray}
        info={info}
        setInfo={setInfo}
      />
      <PaginateProtocols
        protocols={protocols}
        setOpenModalProtocol={setOpenModalProtocol}
        setProtocols={setProtocols}
      />

      {openModalCDI && (
        <MyModal>
          <ModalNewUser
            newUserTipe={newUserTipe}
            setOpenModalNewUser={setOpenModalCDI}
          />
        </MyModal>
      )}
      {openModalProtocol && (
        <MyModal>
          <ModalProtocol
            setOpenModalProtocol={setOpenModalProtocol}
            type={"admin"}
            setUpdate={setProtocols}
          />
        </MyModal>
      )}
      {openModalCDIDetails && (
        <MyModal>
          <ModalCDIDetails
            setOpenModalCDIDetails={setOpenModalCDIDetails}
            details={details}
            setAllArray={setAllArray}
            setInfo={setInfo}
            getCDIs={getCDIs}
          />
        </MyModal>
      )}
    </Container>
  );
}
