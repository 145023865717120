import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { editCDI } from "../../../Conections/Sites";

export default function ProfileCDI({ user, setUser }) {

  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const {token} = JSON.parse(localStorage.getItem("step_med_user"));
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (user?.password){
      if (user?.password !== user?.password_repete) {
        event.preventDefault();
        event.stopPropagation();
        Swal.fire({
          title: "Oh No!",
          text: "Las contraseñas no coinciden",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
        return;
      }
      if (user?.password.length < 6) {
        event.preventDefault();
        event.stopPropagation();
        Swal.fire({
          title: "Oh No!",
          text: "Las contraseñas debe contener al menos 6 caracteres",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
        return;
      }
    }
    setValidated(true);
    editCDI(user, token).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Exito!",
          text: "Se actualizó exitosamente",
          icon: "success",
          confirmButtonText: "Cerrar",
        });
      } else {
        Swal.fire({
          title: "Oh No!",
          text: res.message,
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    })
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="formTables my-5 p-4 shadow">
          <h4 className="col-12">Información institucional</h4>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.name_site}
              name="name_site"
              onChange={handleChange}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por Favor complete el campo correctamente
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              readOnly
              placeholder={user?.email}
              name="email"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.address_site}
              name="address_site"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.phone_site}
              name="phone_site"
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="formTables my-5 shadow">
          <h4 className="col-12">Datos Contacto</h4>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.name}
              name="name"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.surname}
              name="surname"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.phone}
              name="phone"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Control
              type="text"
              placeholder={user?.address}
              name="address"
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="formTables my-5 shadow">
          <h4 className="col-12">User</h4>
          <Form.Group as={Col} md="5">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="**********"
              name="password"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Label>Repetir contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="**********"
              name="password_repete"
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <div className="w-100 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn-padding mb-3 w-25 btn-darkBlue"
            data-dismiss="modal"
          >
            Guardar Cambios
          </button>
        </div>
      </Form>
    </>
  );
}
