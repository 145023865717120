import React, { useState } from "react";
import { Form, InputGroup, Col, Row, Container } from "react-bootstrap";
import Controls from "../../Helpers/Controls";
import "../../Styles/CommonStyles.css";
import { handleRegist } from "../../Conections/Admin";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

export default function NewUserSiteTable({ newUserTipe, setOpenModalNewUser }) {
  const [validated, setValidated] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
    surname: "",
    phone: "",
    address: "",
    name_site: "",
    phone_site: "",
    email: "",
    address_site: "",
    password: "",
    protocol: [],
    n_protocol: null,
    status: "active",
    type: "",
  });

  const history = useHistory();

  const handleChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { token } = JSON.parse(localStorage.getItem("step_med_user"));
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);
    Controls(inputValue);
    if (form.checkValidity()) {
      event.preventDefault();
      Swal.fire({
        title: "",
        allowEscapeKey: false,
        allowOutsideClick: false,
        background: "transparent",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (newUserTipe === "admin") {
        inputValue.type = "admin";
        handleRegist(inputValue, token).then((res) => {
          Swal.close();
          controlSuccess(res);
        });
        return;
      }
      if (newUserTipe === "site") {
        inputValue.type = "cdi";
        handleRegist(inputValue, token).then((res) => {
          Swal.close();
          controlSuccess(res);
        });
      }
    }
  };

  const controlSuccess = (res) => {
    if (res.success) {
      Swal.fire({
        title: "Exito!",
        text: "Se creó exitosamente",
        icon: "success",
        confirmButtonText: "Cerrar",
      }).then(() => {
        setOpenModalNewUser(false);
        history.push("/");
      });
    } else {
      Swal.fire({
        title: "Oh No!",
        text: res.err,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <Container fluid>
      <Form
        noValidate
        validated={validated}
        className='my-5 '
        onSubmit={handleSubmit}
      >
        <Form.Row className='justify-content-center formTables my-5 shadow'>
          <Col className='mb-2 pl-5' xs={12}>
            Datos Personales
          </Col>
          <Form.Group as={Col} xs='12' md='5' controlId='validateNewUser'>
            <Form.Control
              type='text'
              placeholder='Nombre'
              name='name'
              onChange={handleChange}
              className='inputCommon my-1'
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='validateNewEmail'>
            <Form.Control
              type='text'
              placeholder='Apellido'
              name='surname'
              onChange={handleChange}
              className='inputCommon my-1'
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='validateNewAddress'>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='Domicilio'
                aria-describedby='inputGroupPrepend'
                name='address'
                onChange={handleChange}
                className='inputCommon my-1'
                required
              />
            </InputGroup>
          </Form.Group>
          {newUserTipe === "admin" ? (
            <Form.Group as={Col} xs='12' md='5' controlId='validateNewPhone'>
              <InputGroup>
                <Form.Control
                  type='number'
                  placeholder='Numero de documento'
                  aria-describedby='inputGroupPrepend'
                  name='dni'
                  onChange={handleChange}
                  className='inputCommon my-1'
                  required
                />
              </InputGroup>
            </Form.Group>
          ) : (
            <Form.Group as={Col} xs='12' md='5' controlId='validateNewPhone'>
              <InputGroup>
                <Form.Control
                  type='number'
                  placeholder='Numero de teléfono'
                  aria-describedby='inputGroupPrepend'
                  name='phone'
                  onChange={handleChange}
                  className='inputCommon my-1'
                  required
                />
              </InputGroup>
            </Form.Group>
          )}
        </Form.Row>

        {newUserTipe === "admin" ? (
          <>
            <Form.Row className='justify-content-center formTables my-5 shadow'>
              <Col className='mb-2 pl-5' xs={12}>
                Información de contacto
              </Col>
              <Form.Group as={Col} xs='12' md='5' controlId='site_name'>
                <Form.Control
                  type='text'
                  placeholder='Correo electronico'
                  name='email'
                  onChange={handleChange}
                  className='inputCommon my-1'
                  required
                />
                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs='12' md='5' controlId='site_adress'>
                <Form.Control
                  type='text'
                  placeholder='Numero de Telefono'
                  name='phone'
                  className='inputCommon my-1'
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </>
        ) : null}

        {newUserTipe === "site" ? (
          <>
            <Form.Row className='justify-content-center formTables my-5 shadow'>
              <Col className='mb-2 pl-5' xs={12}>
                Información del Institucional
              </Col>
              <Form.Group as={Col} xs='12' md='5' controlId='site_name'>
                <Form.Control
                  type='text'
                  placeholder='Nombre de Institución'
                  name='name_site'
                  onChange={handleChange}
                  className='inputCommon my-1'
                  required
                />
                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs='12' md='5' controlId='site_adress'>
                <Form.Control
                  type='text'
                  placeholder='Dirección de la Institución'
                  name='address_site'
                  className='inputCommon my-1'
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs='12' md='5' controlId='email'>
                <InputGroup>
                  <Form.Control
                    type='email'
                    placeholder='Contacto de la Institución Email'
                    aria-describedby='inputGroupPrepend'
                    name='email'
                    onChange={handleChange}
                    className='inputCommon my-1'
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs='12' md='5' controlId='site_phone'>
                <InputGroup>
                  <Form.Control
                    type='number'
                    placeholder='Teléfono de la Institución'
                    aria-describedby='inputGroupPrepend'
                    name='phone_site'
                    onChange={handleChange}
                    className='inputCommon my-1'
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </>
        ) : null}
        <Form.Row className='justify-content-center formTables my-5 shadow'>
          <Col className='mb-2 pl-5' xs={12}>
            Datos de cuenta
          </Col>
          <Form.Group as={Col} xs='12' md='4'>
            <Form.Control
              type='password'
              placeholder='Contraseña'
              name='password'
              onChange={handleChange}
              className='inputCommon my-1'
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='4'>
            <Form.Control
              type='password'
              placeholder='repetir contraseña'
              name='repet_password'
              onChange={handleChange}
              className='inputCommon my-1'
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Row className='justify-content-center mt-3'>
          <Col xs='6' className='d-flex justify-content-center'>
            <button
              type='submit'
              className='btn-padding w-btn-res btn-darkBlue'
            >
              Crear Nuevo Usuario
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
