import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row, Container } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import AssingProtocol from "../../Helpers/AssingProtocol";
import "../../Styles/CommonStyles.css";
import { useHistory } from "react-router";
import { createNewPatient } from "../../Conections/Admin";
import Swal from "sweetalert2";
import { assignProtocolByPatient, getProtocols } from "../../Conections/Sites";
import { phoneAreas } from "../../Helpers/PhoneAreas";
export default function NewPatient(props) {
  const [validated, setValidated] = useState(false);
  const [protocolsList, setProtocolsList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    address: "",
    phone: "",
    phone_area: "",
    dni: "",
    number: "",
    family_email: "",
    protocol: [],
    n_protocol: 0,
    password: "",
    password_repete: "",
  });
  const { getUserById, editUsers, handleRegistPatient, currentUser } =
    useAuth();
  const history = useHistory();

  useEffect(() => {
    getProtocols({ token: currentUser.token, id: currentUser.id }).then(
      (res) => {
        res.success ? setProtocolsList(res.response) : setProtocolsList([]);
      }
    );
  }, []);

  useEffect(() => {
    setIsEdit(props.history.location.state);
    if (isEdit) {
      let id = props.history.location.state.id;
      getUserById(setUser, id, "patients");
      return;
    }
    getProtocols({ token: currentUser.token, id: currentUser.id }).then(
      (res) => {
        res.success ? setProtocolsList(res.response) : setProtocolsList([]);
      }
    );
  }, []);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (user.password_repete != user.password) {
      event.preventDefault();
      alert("Las contraseñas deben ser iguales");
      return;
    }
    if (
      user.name === "" ||
      user.surname === "" ||
      user.email === "" ||
      user.address === "" ||
      user.phone === "" ||
      user.dni === ""
    ) {
      event.preventDefault();
      alert("Todos los campos deben estar completos");
      return;
    }
    if (isEdit.edit === true) {
      editUsers(user, "patients");
      setValidated(true);
      return;
    } else {
      Swal.fire({
        title: "",
        allowEscapeKey: false,
        allowOutsideClick: false,
        background: "transparent",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      createNewPatient(user, currentUser.id, currentUser.token).then((res) => {
        Swal.close();
        if (res.success) {
          if (user.protocol) {
            assignProtocolByPatient(
              res.user?.id,
              currentUser.token,
              user.protocol
            );
          }
          Swal.fire({
            title: "Exito!",
            text: "Se creó exitosamente",
            icon: "success",
            confirmButtonText: "Cerrar",
          });
        } else {
          Swal.fire({
            title: "Oh No!",
            text: res.message,
            icon: "error",
            confirmButtonText: "Cerrar",
          });
        }
      });
      // handleRegistPatient(user, currentUser.id);
    }
  };
  console.log(user);
  const closeModal = () => {
    history.push({
      pathname: "pacientescdi",
      state: { edit: false },
    });
  };

  return (
    <Container className='formTables mt-5'>
      <div>
        <button
          onClick={closeModal}
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <Form
        noValidate
        validated={validated}
        className='my-5'
        onSubmit={handleSubmit}
      >
        <Form.Row className='justify-content-center my-4'>
          <Col className='mb-2 pl-5' xs={12}>
            Información del paciente
          </Col>
          <Form.Group as={Col} xs='12' md='5' controlId='validationName'>
            <Form.Control
              type='text'
              placeholder='Nombre'
              name='name'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={user.name}
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='validationSurname'>
            <Form.Control
              type='text'
              placeholder='Apellido'
              name='surname'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={user.surname}
              required
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='validationCustomDNI'>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='DNI'
                name='dni'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={user.dni}
                required
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs='12' md='5' controlId='validationCustomEmail'>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='email'
                name='email'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={user.email}
                required
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs='12' md='5' controlId='validationCustomEmail'>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='Número de paciente'
                name='number'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={user.number}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs='12' md='5' controlId='validationCustomEmail'>
            <Form.Control
              as='select'
              name='protocol'
              className='inputCommon my-1'
              defaultValue={user.protocol}
              onChange={handleChange}
            >
              <option value=''>Protocolo</option>
              {protocolsList?.map((p) => (
                <option value={p.id}>{p.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row className='justify-content-center my-4'>
          <Col className='mb-2 pl-5' xs={12}>
            Datos de contacto
          </Col>
          <Form.Group
            as={Col}
            xs='12'
            md='7'
            controlId='validationCustomFamily_email'
          >
            <InputGroup>
              <Form.Control
                type='email'
                placeholder='Email de un Familiar'
                aria-describedby='inputGroupPrepend'
                name='family_email'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={user.family_email}
                required
              />
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='7' controlId='validationCustomphone'>
            <InputGroup className='d-flex justify-content-between'>
              <Form.Control
                as='select'
                name='phone_area'
                className='inputCommon my-1 col-3'
                defaultValue={user.phone_area}
                onChange={handleChange}
                required
              >
                <option value=''>Codigo Pais</option>
                {phoneAreas?.map((area) => (
                  <option value={area.code}>{area.country}</option>
                ))}
              </Form.Control>
              <Form.Control
                type='number'
                placeholder='(Código de Area) Teléfono'
                aria-describedby='inputGroupPrepend'
                name='phone'
                className='inputCommon my-1 col-8'
                onChange={handleChange}
                defaultValue={user.phone}
                required
              />
            </InputGroup>
          </Form.Group>

          <Form.Group
            as={Col}
            xs='12'
            md='7'
            controlId='validationCustomAdress'
          >
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='Domicilio'
                aria-describedby='inputGroupPrepend'
                name='address'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={user.address}
                required
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>

        <Form.Row className='justify-content-center my-4'>
          <Col className='mb-2 pl-5' xs={12}>
            Usuario y Contraseña
          </Col>
          <Form.Group as={Col} xs='12' md='5' controlId='user_email'>
            <Form.Control
              type='password'
              placeholder='Contraseña'
              name='password'
              onChange={handleChange}
              className='inputCommon my-1'
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='password'>
            <Form.Control
              type='password'
              placeholder='Repetir Contraseña'
              name='password_repete'
              onChange={handleChange}
              className='inputCommon my-1'
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Row className='justify-content-center mt-3'>
          <Col xs='6' className='d-flex justify-content-center'>
            <button type='submit' className='btn-padding w-50 btn-darkBlue'>
              Crear Usuario
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
