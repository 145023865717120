function Control() {}

export function UserControl(object, setValidated) {
  if (object.name === "") {
    setValidated(false);
    return;
  }
  if (object.email === "") {
    setValidated(false);

    return;
  }
  if (object.phone === "") {
    setValidated(false);

    return;
  }
  if (object.surname === "") {
    setValidated(false);

    return;
  }
  if (object.password === "") {
    setValidated(false);

    return;
  }
  if (object.repete_password === "") {
    setValidated(false);
    return;
  }
}

export function CDIControl(object, setValidated) {
  if (object.name_site === "") {
    setValidated(false);

    return;
  }
  if (object.address_site === "") {
    setValidated(false);

    return;
  }
  if (object.phone_site === "") {
    setValidated(false);
    return;
  }
}

export default Control;
