import React, { useEffect, useState } from "react";
import ModalNewUser from "./ModalNewUser";
import MyModal from "../MyModal";
import { Tabs, Tab } from "react-bootstrap";
import { useAdmin } from "../../Context/AdminContext";
import { useCDI } from "../../Context/CDIContext";
import UserCDIPagination from "./UserCDIPagination";
import UserAdminPagination from "./UserAdminPagination";

export default function Usuarios() {
  const [openModalNewUser, setOpenModalNewUser] = useState(false);
  const [newUserTipe, setNewUserTipe] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [allCDI, setAllCDI] = useState([]);
  const [allArray, setAllArray] = useState([]);
  const [allArrayAdmin, setAllArrayAdmin] = useState([]);
  const { getAdmins } = useAdmin();
  const { getCDIs, loadingInfo } = useCDI();
  const { type } = JSON.parse(localStorage.getItem('step_med_user'));

  useEffect(() => {
    getAdmins(setAllUsers, setAllArrayAdmin);
    getCDIs("", "cdis", setAllCDI, setAllArray);
  }, []);

  const openModal = (type) => {
    setOpenModalNewUser(true);
    setNewUserTipe(type);
  };

  function handleSearchAdmin(event) {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = allArrayAdmin.filter((item) => 
      item.name.toLowerCase().includes(searched.toLowerCase()) ||
      item.surname.toLowerCase().includes(searched.toLowerCase())
    );
    setAllUsers(newSearch)
  }

  function handleSearchCDI(event) {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = allArray.filter((item) => 
      item.name_site.toLowerCase().includes(searched.toLowerCase()) ||
      item.name.toLowerCase().includes(searched.toLowerCase()) ||
      item.surname.toLowerCase().includes(searched.toLowerCase())
    );
    setAllCDI(newSearch)
  }

  return (
    <>
      <div className="container-fluid backgroudCommon ">
        <Tabs
          defaultActiveKey="Admin"
          transition={false}
          id="noanim-tab-example"
          className="pt-5"
        >
          <Tab eventKey="Admin" title="Admin">
            <UserAdminPagination
              allUsers={allUsers}
              openModal={openModal}
              loadingInfo={loadingInfo}
              handleSearchAdmin={handleSearchAdmin}
              userType={type}
            />
          </Tab>
          <Tab eventKey="Site" title="Site">
            <UserCDIPagination
              allCDI={allCDI}
              openModal={openModal}
              loadingInfo={loadingInfo}
              handleSearchCDI={handleSearchCDI}
              userType={type}
            />
          </Tab>
        </Tabs>
      </div>
      {openModalNewUser && (
        <MyModal>
          <ModalNewUser
            setOpenModalNewUser={setOpenModalNewUser}
            newUserTipe={newUserTipe}
          />
        </MyModal>
      )}
    </>
  );
}
