import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(
		JSON.parse(localStorage.getItem('step_med_user')) || ''
	);

	const [loading, setLoading] = useState(false);
	// const [mensaje, setMensaje] = useState("");

	// const url = process.env.NODE_ENV !== "development" ? "http://vps-2265777-x.dattaweb.com/api" :"http://localhost:4000";
	const url =
		process.env.NODE_ENV !== 'development'
			? 'https://api.followmed.com.ar/api'
			: 'https://api.followmed.com.ar/api';

	const headers = {
		'Content-Type': 'application/json',
		Authorization: currentUser?.token,
	};

	let history = useHistory();

	const redirect = () => {
		history.push('/');
	};

	function handleRegistAdmin({
		email,
		password,
		name,
		surname,
		phone,
		address,
	}) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/`,
				{
					email: email,
					password: password,
					name: name,
					surname: surname,
					phone: phone,
					address: address,
					type: 'admin',
				},
				{
					headers: headers,
				}
			)
			.then(function (response) {
				Swal.close();

				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			});
	}

	function handleRegistCDI(inputValue) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		inputValue.email = inputValue.email.toLowerCase();
		axios
			.post(
				`${url}/users/`,
				{
					name: inputValue.name,
					surname: inputValue.surname,
					phone: inputValue.phone,
					address: inputValue.address,
					name_site: inputValue.name_site,
					phone_site: inputValue.phone_site,
					email: inputValue.email,
					address_site: inputValue.address_site,
					password: inputValue.password,
					protocol: inputValue.protocol,
					type: 'cdi',
				},
				{ headers }
			)
			.then(function (response) {
				Swal.close();

				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				Swal.close();

				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			});
	}

	function handleRegistPatient(inputValue, id) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(`${url}/users/`, {
				name: inputValue.name,
				surname: inputValue.surname,
				email: inputValue.email,
				address: inputValue.address,
				phone: inputValue.phone,
				dni: inputValue.dni,
				contact_phone: inputValue.contact_phone,
				family_email: inputValue.family_email,
				password: inputValue.password,
				cdi: id,
				type: 'patient',
				protocol: [],
				n_protocol: 0,
				status: 'green',
			})
			.then(function (response) {
				Swal.close();
				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			});
	}
	function login(email, password) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/login`,
				{
					email: email,
					password: password,
				},
				{
					headers: headers,
				}
			)
			.then((response) => {
				Swal.close();
				let user = {
					nombre: response.data?.user.email.toLowerCase(),
					token: response.data?.user.token,
					type: response.data?.user.type,
					id: response.data?.user.id,
				};

				if (response.data.success) {
					localStorage.setItem('step_med_user', JSON.stringify(user));
					setCurrentUser(user);
					redirect();
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				Swal.close();

				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
				setLoading(false);
			});
	}

	function logout() {
		localStorage.removeItem('step_med_user');
		setCurrentUser('');
		return currentUser;
	}

	function getUserById(setUser, id, user) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/userList/${id}`,
				{
					collection: user,
				},
				{ headers }
			)
			.then(function (response) {
				Swal.close();
				setUser(response.data.response);
			})
			.catch(function (error) {
				Swal.close();
			});
	}

	const value = {
		currentUser,
		loading,
		setLoading,
		handleRegistAdmin,
		handleRegistCDI,
		login,
		logout,
		getUserById,
		handleRegistPatient,
		setCurrentUser,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
