import React, { useEffect, useState } from "react";
import PaginateComponent from "../../../Helpers/PaginateComponent";
import CDITables from "./CDITable";

export default function PaginateCDI({
  openModal,
  setOpenModalCDIDetails,
  setDetails,
  loadingInfo,
  getCDIs,
  allArray,
  setAllArray,
  setInfo,
  info,
}) {
  const [orderInstitucion, setOrderInstitucion] = useState(true);
  const [mensaje, setMensaje] = useState(false);

  useEffect(() => {
    getCDIs("", "cdis", setInfo, setAllArray);
  }, []);

  const upDownInstitucion = () => {
    if (orderInstitucion) {
      setOrderInstitucion(false);
      setInfo(
        info.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
      );
    } else {
      setOrderInstitucion(true);
      setInfo(
        info.sort(function (a, b) {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        })
      );
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(info?.length / PER_PAGE);
  const currentPageData = info?.slice(offset, offset + PER_PAGE);

  const search = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = allArray.filter((item) =>
      item.name_site.toLowerCase().includes(searched.toLowerCase())
    );
    setInfo(newSearch);
  };

  return (
    <>
      <CDITables
        info={currentPageData}
        setOpenModalCDI={openModal}
        setOpenModalCDIDetails={setOpenModalCDIDetails}
        setDetails={setDetails}
        loadingInfo={loadingInfo}
        upDown={upDownInstitucion}
        orderInstitucion={orderInstitucion}
        search={search}
        mensaje={mensaje}
      />

      <PaginateComponent
        pageCount={pageCount}
        currentPageData={currentPageData}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
