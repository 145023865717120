import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HomeTableCDI from "./HomeTableCDI";
import MyModalCDI from "../MyModalCDI";
import ModalProtocol from "../../Components/Home/ModalProtocol";

import "../../Styles/ResumePEP.css";
import { getSiteTotals } from "../../Conections/Sites";
import { useAuth } from "../../Context/AuthContext";

export default function Home() {
  const { currentUser } = useAuth();
  const [protocolsList, setProtocolsList] = useState([]);
  const [openModalProtocol, setOpenModalProtocol] = useState(false);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    getTotals();
  }, []);

  const getTotals = () => {
    getSiteTotals(currentUser).then((resp) => {
      setTotals(resp?.totals);
    });
  } 

  return (
    <div className="backgroudCommon">
      <Container className="mt-3 divider">
        <Row className="text-center divider pt-5 pb-1">
          <Col className="mb-2">
            <h2>Resumen</h2>
          </Col>
        </Row>
        <Row className="justify-content-around my-5 p-3 pb-5">
          <Col className="text-center boxPEP" xs="5" md="3">
            <Row>
              <Col xs={12} className="PEP-title">
                <h5>Total Pacientes</h5>
              </Col>
              <Col xs={12} className="cardPEP shadow bg-total patient-total">
                <div className="text-dark total-number px-2">
                  {totals?.patients}
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="text-center boxPEP" xs="5" md="3">
            <Row>
              <Col xs={12} className="PEP-title">
                <h5>Total Eventos</h5>
              </Col>
              <Col xs={12} className="cardPEP shadow bg-total event-total">
                <div className="text-dark total-number px-2">
                  {totals?.events}
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="text-center boxPEP mb-5" xs="5" md="3">
            <Row>
              <Col xs={12} className="PEP-title">
                <h5>Total Protocolos</h5>
              </Col>
              <Col xs={12} className="cardPEP shadow bg-total protocol-total">
                <div className="text-dark total-number px-2">
                  {totals?.protocols}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <HomeTableCDI
          setOpenModalProtocol={setOpenModalProtocol}
          protocolsList={protocolsList}
          setProtocolsList={setProtocolsList}
          getSiteTotals = {getTotals}
        />
      </Container>
      {openModalProtocol && (
        <MyModalCDI>
          <ModalProtocol
            setOpenModalProtocol={setOpenModalProtocol}
            type={"cdi"}
            setUpdate={setProtocolsList}
          />
        </MyModalCDI>
      )}
    </div>
  );
}
