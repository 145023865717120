import LoginRoute from "./Routes/LoginRoute";
import ResetPassword from "./Routes/ReserPassword";
import { AuthProvider } from "./Context/AuthContext";
import { AdminProvider } from "./Context/AdminContext";
import { CDIProvider } from "./Context/CDIContext";
import DashboardRoute from "./Routes/DashboardRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import RegistAdmin from "./Routes/RegistAdmin";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <AdminProvider>
          <CDIProvider>
            <Switch>
              <Route exact path="/login" component={LoginRoute} />
              <Route exact path="/registadmin" component={RegistAdmin} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <PrivateRoute path="/" component={DashboardRoute} />
              <Route path="*">Pagina no encontrada</Route>
            </Switch>
          </CDIProvider>
        </AdminProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
