import { React, useState, useRef, useEffect } from 'react';
import { Col, Row, Table, Button, InputGroup } from 'react-bootstrap';
import {
	FaSistrix,
	FaPlusCircle,
	FaRegTrashAlt,
	FaSortDown,
	FaSortUp,
} from 'react-icons/fa';
import { useCDI } from '../../Context/CDIContext';
import PointDown from '../../Components/Home/HomeComponents/PointDown';
import PaginateComponent from '../../Helpers/PaginateComponent';
import '../../Styles/CommonStyles.css';
import { useAuth } from '../../Context/AuthContext';
import Swal from 'sweetalert2';
import {
	deleteSiteProtocol,
	getProtocols,
	getSite,
} from '../../Conections/Sites';
import MyModalCDI from '../MyModalCDI';
import AddProtocol from './AddProtocol';

export default function HomeTableCDI({
	setOpenModalProtocol,
	protocolsList,
	setProtocolsList,
	getSiteTotals,
}) {
	const { loadingInfo, listProtocols } = useCDI();
	const { currentUser } = useAuth();
	const [protocols, setProtocols] = useState();
	const [addProtocol, setAddProtocol] = useState(false);
	const myRef = useRef(null);
	const [user, setUser] = useState({ protocol: [] });
	const [update, setUpdate] = useState(false);
	const [mensaje, setMensaje] = useState(false);
	const token = JSON.parse(localStorage.getItem('step_med_user'));
	const updateSite = () => {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		getSite(currentUser.id, currentUser.token)
			.then((resp) => {
				setUser(resp?.response);
				Swal.close();
			})
			.catch((err) => {
				Swal.close();
			});
		setUpdate((update) => !update);
	};

	useEffect(() => {
		getProtocols(currentUser).then((resp) => {
			setProtocols(resp.response);
		});
	}, [update]);

	useEffect(() => {
		updateSite();
	}, []);

	const deleteProtocols = (info) => {
		Swal.fire({
			title: '¿Eliminar Protocolo?',
			text: `Se eliminará el prótocolo ${info.name} (${info.number})!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: '',
					allowEscapeKey: false,
					allowOutsideClick: false,
					background: 'transparent',
					showConfirmButton: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				deleteSiteProtocol(info.id, token?.token, token?.id)
					.then((resp) => {
						Swal.close();
						Swal.fire({
							title: 'Exito!',
							text: 'Se borro exitosamente',
							icon: 'success',
							confirmButtonText: 'Cerrar',
						}).then(() => {
							getSiteTotals();
							updateSite();
							setUpdate((update) => !update);
						});
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	};

	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 5;
	const offset = currentPage * PER_PAGE;
	const pageCount = Math.ceil(user?.protocol?.length / PER_PAGE);
	const currentPageData = protocols?.slice(offset, offset + PER_PAGE);

	const handleAddProtocol = () => {
		setAddProtocol(true);
	};

	const handleAddProtocolClose = () => {
		setAddProtocol(false);
	};

	const search = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = user.protocol.filter(
			(item) =>
				item.name.toLowerCase().includes(searched.toLowerCase()) ||
				item.number.includes(searched)
		);
		newSearch.length == 0 ? setMensaje(true) : setMensaje(false);
		setProtocols(newSearch);
	};

	return (
		<>
			<Row className='text-center divider pt-5 pb-1'>
				<Col className='mb-2'>
					<h2 ref={myRef}>Protocolos</h2>
				</Col>
			</Row>
			<div className='mt-5 w-100 d-flex in-but-pos-res'>
				<div className='inicio-btn-site  w-40-res'>
					<div className='input-group '>
						<InputGroup>
							<input
								type='text'
								className='form-control '
								placeholder='Buscar...'
								onChange={search}
							/>
							<InputGroup.Text>
								<FaSistrix />
							</InputGroup.Text>
						</InputGroup>
					</div>
				</div>
				<div className='btn-protocols'>
					<button
						variant='secondary'
						className='text-center btn-padding w-100 btn-darkBlue'
						onClick={() => handleAddProtocol()}
					>
						Asignar Protocolo
					</button>
					<button
						variant='secondary'
						className='text-center btn-darkBlue addButton'
						onClick={() => setOpenModalProtocol(true)}
					>
						Nuevo <FaPlusCircle />
					</button>
				</div>
			</div>
			<Row className='justify-content-around mt-5-incio-res p-3'>
				<div className='headerTable'>Lista de Protocolos </div>
				<div className='m-auto'>{loadingInfo ? 'Cargando' : ''}</div>
				<div className='m-auto'>{mensaje ? 'No hay resultados' : ''}</div>

				<div className='table-responsive customTables'>
					<Table hover className='table-bordered m-0' size='sm'>
						<thead>
							<tr>
								<th className='text-center' rowSpan='3' colSpan='3'>
									Nombre
								</th>
								<th className='text-center' rowSpan='3' colSpan='3'>
									Acrónimo
								</th>
								<th className='text-center' rowSpan='3' colSpan='3'>
									N Protocolo
								</th>
								<th className='text-center' rowSpan='3' colSpan='3'>
									N Pacientes
								</th>
							</tr>
						</thead>
						<tbody>
							{protocolsList &&
								currentPageData?.map((info, i) => (
									<tr key={i}>
										<td className='text-center' colSpan='3'>
											{info.technicalName}
										</td>

										<td className='text-center' colSpan='3'>
											{info.name}
										</td>

										<td className='text-center' colSpan='3'>
											{info.number}
										</td>

										<td className='text-center' colSpan='3'>
											{info.n_patients}
										</td>

										<td className='text-center' colSpan='1'>
											<FaRegTrashAlt
												className='delet text-secondary'
												onClick={() => deleteProtocols(info)}
											/>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</Row>
			<PaginateComponent
				pageCount={pageCount}
				currentPageData={currentPageData}
				setCurrentPage={setCurrentPage}
			/>
			{addProtocol ? (
				<MyModalCDI>
					<AddProtocol
						user={currentUser}
						modalClose={handleAddProtocolClose}
						updateTable={updateSite}
						updateTotal={getSiteTotals}
						cdi={user}
					/>
				</MyModalCDI>
			) : null}
			<PointDown reff={myRef} />
		</>
	);
}
