import React, { useEffect, useState } from "react";
import { Form, InputGroup, Col, Row, Container } from "react-bootstrap";
import AsignarProtocolo from "../../../ComponentsCDI/Profile/Components/AsignarProtocolo";
import { editCDI } from "../../../Conections/Sites";
import "../../../Styles/CommonStyles.css";
import Swal from "sweetalert2";

export default function CDIDetails({
  details,
  readOnly,
  setAllArray,
  setInfo,
  getCDIs,
}) {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({ ...details });
  const { id, token } = JSON.parse(localStorage.getItem("step_med_user")) || "";

  useEffect(() => {}, [user]);
  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      Swal.fire({
        title: "",
        allowEscapeKey: false,
        allowOutsideClick: false,
        background: "transparent",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      editCDI(user, token).then((res) => {
        Swal.close();
        if (res.success) {
          Swal.fire({
            title: "Exito!",
            text: "Se editó exitosamente",
            icon: "success",
            confirmButtonText: "Cerrar",
          }).then(() => {
            getCDIs("", "cdis", setInfo, setAllArray);
          });
        } else {
          Swal.fire({
            title: "Oh No!",
            text: res.message,
            icon: "error",
            confirmButtonText: "Cerrar",
          });
        }
      });
    }

    setValidated(true);
  };

  return (
    <Container fluid>
      <Form
        noValidate
        validated={validated}
        className='my-5 '
        onSubmit={handleSubmit}
      >
        <Form.Row className='justify-content-center formTables my-5'>
          <Col className='mb-2 pl-5' xs={12}>
            Información Institucional
          </Col>
          <Form.Group as={Col} xs='12' md='5' controlId='CDIDetailsName'>
            <Form.Control
              type='text'
              placeholder='Nombre de la institución'
              readOnly={readOnly}
              name='name_site'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details?.name_site}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='CDIDetailsAddress'>
            <Form.Control
              type='text'
              placeholder='Dirección de la institución'
              readOnly={readOnly}
              name='address_site'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details?.address_site}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} xs='12' md='5' controlId='CDIDetailsEmail'>
            <InputGroup>
              <Form.Control
                type='email'
                placeholder='Email de la institución'
                readOnly={readOnly}
                aria-describedby='inputGroupPrepend'
                name='email'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={details.email}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs='12' md='5' controlId='CDIDetailsPhone'>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='Numero de contacto de la institución'
                readOnly={readOnly}
                aria-describedby='inputGroupPrepend'
                name='phone_site'
                className='inputCommon my-1'
                onChange={handleChange}
                defaultValue={details.phone_site}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>

        <Form.Row className='justify-content-center formTables my-5'>
          <Col className='mb-2 pl-5' xs={12}>
            Datos Contacto
          </Col>
          <Form.Group
            as={Col}
            xs='12'
            md='5'
            controlId='CDIDetailscontact_name'
          >
            <Form.Control
              type='text'
              placeholder='Nombre del contacto'
              readOnly={readOnly}
              name='name'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details.name}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            xs='12'
            md='5'
            controlId='CDIDetailscontact_phone'
          >
            <Form.Control
              type='text'
              placeholder='Apellido del contacto'
              readOnly={readOnly}
              name='surname'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details.surname}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            xs='12'
            md='5'
            controlId='CDIDetailscontact_email'
          >
            <Form.Control
              type='text'
              placeholder='Direccion de contacto'
              readOnly={readOnly}
              name='address'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details?.address}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            xs='12'
            md='5'
            controlId='CDIDetailscontact_cargo'
          >
            <Form.Control
              type='text'
              placeholder='Teléfono del contacto'
              readOnly={readOnly}
              name='phone'
              className='inputCommon my-1'
              onChange={handleChange}
              defaultValue={details?.phone}
            />
            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        {!readOnly ? (
          <button
            type='submit'
            className='btn-darkBlue d-flex m-auto p-2'
            data-dismiss='modal'
          >
            Guardar Cambios
          </button>
        ) : null}
        <AsignarProtocolo user={details} token={token} />
      </Form>
    </Container>
  );
}
