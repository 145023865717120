import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../Styles/ResumePEP.css";
import "../../../Styles/CommonStyles.css";
import { getTotalInfo } from "../../../Conections/Admin";

export default function ResumePEP() {
  const [info, setInfo] = useState({});
  useEffect(() => {
    getTotalInfo()
      .then((resp) => {
        setInfo(resp?.totals);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="  container-resize">
      <Row className="text-center divider pt-4 pb-1 mt-4">
        <Col className="mb-2">
          <h2>Resumen</h2>
        </Col>
      </Row>
      <Row className="justify-content-center  mt-5">
        <Col className="text-center boxPEP mr-5-res" xs="5" md="5" lg={3}>
          <Row>
            <Col xs={12} className="PEP-title">
              <h5>Total Pacientes</h5>
            </Col>
            <Col xs={12} className="cardPEP shadow patient-total bg-total">
              <div className="text-dark total-number px-2">{info.patients}</div>
            </Col>
          </Row>
        </Col>
        <Col className="text-center boxPEP ml-5-res" xs="5" md="5" lg={3}>
          <Row>
            <Col xs={12} className="PEP-title">
              <h5>Total Eventos</h5>
            </Col>
            <Col xs={12} className="cardPEP shadow event-total bg-total">
              <div className="text-dark total-number px-2">{info.events}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center pb-4">
        <Col className="text-center boxPEP mr-5-res" xs="5" md="5" lg={3}>
          <Row>
            <Col xs={12} className="PEP-title">
              <h5>Total Protocolos</h5>
            </Col>
            <Col xs={12} className="cardPEP shadow protocol-total bg-total">
              <div className="text-dark total-number px-2">
                {info.protocols}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="text-center boxPEP ml-5-res" xs="5" md="5" lg={3}>
          <Row>
            <Col xs={12} className="PEP-title">
              <h5>Total Site</h5>
            </Col>
            <Col xs={12} className="cardPEP shadow sites-total bg-total">
              <div className="text-dark total-number px-2">{info.cdis}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
