import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row, Container } from "react-bootstrap";
import Swal from "sweetalert2";

import { editProtocol } from "../Conections/Sites";

export default function EditProtocols({ infoProtocolEdit }) {
  const [inputValue, setInputValue] = useState(infoProtocolEdit);

  const handleChange = (event) => {
    setInputValue((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeSelect = (event, info, index) => {
    setInputValue((state) => {
      const duration = state.duration.map((dur) => ({ ...dur }));
      duration[index] = {
        name: index.toString(),
        frequency: event.target.value,
      };
      return {
        ...state,
        duration,
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { token } = JSON.parse(localStorage.getItem("step_med_user"));
    const payload = {
      ...inputValue,
      duration: inputValue.duration.slice(0, inputValue.visits),
    };
    Swal.fire({
      title: "",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    editProtocol(infoProtocolEdit.n_protocol, token, payload).then((res) => {
      Swal.close();
      if (res.success) {
        Swal.fire({
          title: "Exito!",
          text: "Se editó exitosamente",
          icon: "success",
          confirmButtonText: "Cerrar",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Oh No!",
          text: res.message,
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    });
  };

  const numberVisitis = parseInt(inputValue?.visits);
  const vistisArray = numberVisitis ? [...Array(numberVisitis).keys()] : [];

  return (
    <Container>
      <Form className='my-5' onSubmit={handleSubmit}>
        <Form.Row className='justify-content-center formTables shadow'>
          <Col className='mb-2 pl-5' xs={12}>
            Información institucional
          </Col>
          <Form.Group as={Col} md='5' controlId='protocol_home'>
            <Form.Control
              type='text'
              defaultValue={infoProtocolEdit.technicalName}
              name='technicalName'
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} md='5' controlId='protocol_home'>
            <Form.Control
              type='text'
              defaultValue={infoProtocolEdit.name}
              name='name'
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md='5' controlId='protocol_n'>
            <Form.Control
              type='number'
              defaultValue={infoProtocolEdit.number}
              name='number'
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} xs='5' controlId='visit_time'>
            <InputGroup>
              <Form.Control
                type='number'
                defaultValue={infoProtocolEdit.visits}
                aria-describedby='inputGroupPrepend'
                name='visits'
                onChange={handleChange}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        {vistisArray.map((_, index) => {
          const info = infoProtocolEdit?.duration[index] || {
            name: index,
            frequency: "",
          };
          return (
            <Form.Row className='justify-content-center'>
              <Form.Group as={Col} md='4'>
                <Form.Label>Frecuencia de visitas</Form.Label>
                <Form.Control
                  name={"frequency"}
                  onChange={(event) => handleChangeSelect(event, info, index)}
                  type='number'
                  placeholder={`${info.frequency} días`}
                />
              </Form.Group>
            </Form.Row>
          );
        })}

        <Row className='justify-content-center mt-3'>
          <Col xs='6' className='d-flex justify-content-center'>
            <button type='submit' className='btn-padding w-50 btn-darkBlue'>
              Guardar Cambios
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
