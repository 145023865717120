import React, { useState } from "react";
import PaginateComponent from "../../Helpers/PaginateComponent";
import UserTable from "./UserTable";

export default function UserCDIPagination({
  setAllCDI,
  allCDI,
  openModal,
  loadingInfo,
  setSearchCDI,
  handleSearchCDI,
  mensajeCDI,
  userType
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(allCDI?.length / PER_PAGE);
  const currentPageData = allCDI?.slice(offset, offset + PER_PAGE);
  return (
    <>
      <UserTable
        setAllCDI={setAllCDI}
        allCDI={currentPageData}
        openModal={openModal}
        loadingInfo={loadingInfo}
        setSearchCDI={setSearchCDI}
        handleSearchCDI={handleSearchCDI}
        mensajeCDI={mensajeCDI}
        userType={userType}
      />
      <PaginateComponent
        pageCount={pageCount}
        currentPageData={currentPageData}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
