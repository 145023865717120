import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import AssingProtocol from "../../../Helpers/AssingProtocol";

export default function AsignarProtocolo({ user, token }) {
  useEffect(() => {}, []);

  return (
    <Row className="formTables my-5 shadow">
      <h4 className="col-12">Protocolos</h4>

      <AssingProtocol user={user} token={token} />
    </Row>
  );
}
