import React from "react";
import GetTurns from "../GetTurns";
import moment from "moment";

function Days({ res, editModalShow, vista, month, year }) {
  const { Turnos } = GetTurns();
  let days = [];
  let date = [];
  const cols = [];
  const dateChanged = `${year.format("YYYY")}/${month.format("MM")}/${moment().date()}`;

  for (let i = 0; i < vista; i++) {
    days.push(moment(dateChanged).add(i, "days").format("dddd DD/MM"));
    date.push(moment(dateChanged).add(i, "days").format("DD/MM/YYYY"));
    const day = days[i];
    const dt = date[i];
    const col = { day, dt };
    cols.push(col);
  }
  if (vista === 1) {
    return (
      <div className="tablep overflow-auto d-flex flex-column align-items-center">
        <div className="theadp d-flex">
          <div className="trp d-flex flex-row m-0">
            <div className="thp d-flex align-items-center justify-content-center calendar-item1">
              <b>{moment(dateChanged).format("dddd-DD")}</b>
            </div>
          </div>
        </div>
        <div className="tbodyp d-flex">
          <div className="calendar-item1">
            {Turnos(moment().format("DD/MM/YY"), res, editModalShow)}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="tablep overflow-auto">
        <div>
          <div className="theadp d-flex">
            <div className="trp d-flex flex-row m-0">
              {cols.map((c) => (
                <div className="thp d-flex align-items-center justify-content-center calendar-item ">
                  <b>{c.day}</b>
                </div>
              ))}
            </div>
          </div>
          <div className="tbodyp d-flex">
            {cols.map((c) => {
              return (
                <div className="calendar-item">
                  {Turnos(c.dt, res, editModalShow)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Days;
