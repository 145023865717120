import React, { useState } from "react";
import { Col, Row, Table, Button, InputGroup } from "react-bootstrap";
import { FaSistrix, FaPlusCircle, FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import ModalCDIDetails from "../Home/ModalCDIDetails";
import MyModal from "../MyModal";
import "../../Styles/CommonStyles.css";
import moment from "moment";
import Swal from "sweetalert2";
import { deleteUser } from "../../Conections/Admin";

export default function UserTable({
  openModal,
  allCDI,
  loadingInfo,
  handleSearchCDI,
  userType
}) {

  const [openModalCDIDetails, setOpenModalCDIDetails] = useState(false);
  const [details, setDetails] = useState({});
  const confimrDelete = (user) => {
		Swal.fire({
			title: '¿Eliminar usuario?',
			text: `Se eliminará usuario ${user.name_site}!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
			    deleteUser(user.id, 'cdis').then((res) => {
						if (res.success) {
							window.location.reload();
							Swal.fire({
								title: 'Exito!',
								text: 'Se eliminó exitosamente',
								icon: 'success',
								confirmButtonText: 'Cerrar',
							});
						} else {
							Swal.fire({
								title: 'Oh No!',
								text: res.message,
								icon: 'error',
								confirmButtonText: 'Cerrar',
							});
						}
					});
			}
		});
	};

  return (
    <>
      <Row className='text-center divider my-4'>
        <Col className='mb-2'>
          <h2>Usuarios Site</h2>
        </Col>
      </Row>
      <Row className='align-items-center'>
        <Col xs={5} className='no-col'>
          <InputGroup>
            <input
              type='text'
              className='form-control '
              placeholder='Buscar...'
              onChange={handleSearchCDI}
            />
            <InputGroup.Text>
              <FaSistrix />
            </InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={3} className='btn-t-3-res no-col'>
          <button
            className='text-center w-100 btn-padding btn-darkBlue'
            onClick={() => openModal("site")}
          >
            Nuevo Usuario <FaPlusCircle />
          </button>
        </Col>
      </Row>
      <Row className='justify-content-around mt-1 p-3'>
        <div className='headerTable'>Lista de Sites </div>
        {loadingInfo && <div className='text-center'>Cargando...</div>}
        <div className='table-responsive customTables'>
          <Table hover className='table-bordered m-0' size='sm'>
            <thead>
              <tr>
                <th className='text-center'>Site</th>
                <th className='text-center'>Responsable</th>
                <th className='text-center'>Teléfono Responsable</th>
                <th className='text-center'>E-Mail Responsable</th>
                <th className='text-center'>Ultima conexión</th>
              </tr>
            </thead>
            <tbody>
              {allCDI.map((user, i) => {
                if (user.type === "cdi") {
                  return (
										<tr key={i}>
											<td className='text-center'>{user.name_site}</td>
											<td className='text-center'>
												{user.surname} {user.name}
											</td>
											<td className='text-center'>{user.phone}</td>
											<td className='text-center'>{user.email}</td>
											<td className='text-center'>{user.lastConnection ? moment(user.lastConnection).format('DD/MM/YYYY hh:mm A') : ''}</td>
											<td className='text-center' colSpan='1'>
												<FaPencilAlt
													className='edit text-secondary'
													onClick={() => {
														setOpenModalCDIDetails(true);
														setDetails(user);
													}}
												/>
											</td>
											{userType === 'superAdmin' ? (
												<td className='text-center delet text-secondary'>
													<FaRegTrashAlt onClick={() => confimrDelete(user)}/>
												</td>
											) : null}
										</tr>
									);
                }
                return true;
              })}
            </tbody>
          </Table>
        </div>
      </Row>
      {openModalCDIDetails && (
        <MyModal>
          <ModalCDIDetails
            setOpenModalCDIDetails={setOpenModalCDIDetails}
            details={details}
          />
        </MyModal>
      )}
    </>
  );
}
