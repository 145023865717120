import React, { useState } from "react";
import PaginateComponent from "../../Helpers/PaginateComponent";
import AdminTable from "./AdminTable";

export default function UserAdminPagination({
  setAllUsers,
  allUsers,
  openModal,
  loadingInfo,
  setSearchAdmin,
  handleSearchAdmin,
  mensaje,
  userType
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(allUsers?.length / PER_PAGE);
  const currentPageData = allUsers?.slice(offset, offset + PER_PAGE);
  return (
    <>
      <AdminTable
        allUsers={currentPageData}
        openModal={openModal}
        setAllUsers={setAllUsers}
        loadingInfo={loadingInfo}
        setSearchAdmin={setSearchAdmin}
        handleSearchAdmin={handleSearchAdmin}
        mensaje={mensaje}
        userType={userType}
      />
      <PaginateComponent
        pageCount={pageCount}
        currentPageData={currentPageData}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
