import React, { useState, useEffect } from 'react';
import PaginateComponent from '../../../Helpers/PaginateComponent';
import ProtocolTable from './ProtocolTable';
import { useCDI } from '../../../Context/CDIContext';

export default function PaginateProtocols({
	protocols,
	setOpenModalProtocol,
	setProtocols,
}) {
	const { listProtocols } = useCDI();
	const [allArray, setAllArray] = useState([]);
	const [protocolList, setProtocolList] = useState([]);

	useEffect(() => {
		listProtocols(setProtocols, setAllArray);
		listProtocols(setProtocolList);
	}, []);

	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 5;
	const offset = currentPage * PER_PAGE;
	const pageCount = Math.ceil(protocols?.length / PER_PAGE);
	const currentPageData = protocolList?.slice(offset, offset + PER_PAGE);

	const search = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter(
			(item) =>
				item.name.toLowerCase().includes(searched.toLowerCase()) ||
				item.number.includes(searched)
		);
		setProtocolList(newSearch);
	};

	return (
		<>
			<ProtocolTable
				info={currentPageData}
				setOpenModalProtocol={setOpenModalProtocol}
				setProtocols={setProtocols}
				search={search}
			/>
			<PaginateComponent
				pageCount={pageCount}
				currentPageData={currentPageData}
				setCurrentPage={setCurrentPage}
			/>
		</>
	);
}
