import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, InputGroup } from "react-bootstrap";
import { FaSistrix, FaDownload } from "react-icons/fa";
import EventoPaginate from "./EventoPaginate";
import { useCDI } from "../../Context/CDIContext";
import ContactPaginate from "./ContactPaginate";
import { filterByCDI, getSite, getPatientList } from "../../Conections/Sites";
import { useAuth } from "../../Context/AuthContext";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";

export default function EventosCDI() {
  const { loadingInfo, protocolList } = useCDI();
  const { currentUser } = useAuth();
  const [info, setInfo] = useState([]);
  const [allArray, setAllArray] = useState([]);
  const [allArrayContact, setAllArrayContact] = useState([]);
  const [mensaje, setMensaje] = useState(false);
  const [patientsEvent, setPatientsEvent] = useState([]);
  const [patientsDate, setPatientsDate] = useState([]);
  useEffect(() => {
    getCDIEvents();
  }, []);

  const [otherEvents, setOtherEvents] = useState([]);
  const [needContact, setNeedContact] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setPatientsEvent(allArray)
    setPatientsDate(allArray)
  }, [allArray])

  useEffect(() => {
    const patients = [];
    for (const patient of allArray) {
      if (patientsEvent.indexOf(patient) > -1 && patientsDate.indexOf(patient) > -1) {
        patients.push(patient)
      }
    }
    setOtherEvents(patients)
  }, [patientsDate, patientsEvent, ])

  const getCDIEvents = () => {
    Swal.fire({
      title: "Cargando Eventos",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    filterByCDI(currentUser)
      .then((resp) => {
        setEvents(resp);
        let needContact = resp?.filter((item) => {
          return item.eventName == "Necesito Contactarme";
        });
        let otherEvents = resp?.filter((item) => {
          return item.eventName != "Necesito Contactarme";
        });
        setOtherEvents(otherEvents);
        setAllArray(otherEvents);
        setNeedContact(needContact);
        setAllArrayContact(needContact);
        Swal.close();
      })
      .catch((err) => {
        console.log(err);
        Swal.close();
      });
  };

  // =============== Search Other Events ============

  const searchOtherEvents = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = allArray.filter(
      (item) =>
        item.user.dni.includes(searched) ||
        item.user.number.includes(searched) ||
        item.user.name.toLowerCase().includes(searched.toLowerCase()) ||
        item.user.surname.toLowerCase().includes(searched.toLowerCase())
    );
    searched.length == 0 ? setOtherEvents(allArray) : setOtherEvents(newSearch);
  };

  const handleEventSelect = (event) => {
    let newSearch = allArray.filter((item) => {
      return item.eventName === event.target.value;
    });
    if (event.target.value === "no") {
      setPatientsEvent(allArray);

      return;
    } else
      newSearch.length > 0
        ? setPatientsEvent(newSearch)
        : setPatientsEvent(allArray);
  };

  const handleDateSelect = (event) => {
    let newSearch = allArray.filter((item) => {
      let f = new Date(item.createDate);
      let mes = f.getMonth() + 1;
      if (mes <= 9) {
        mes = `0${mes}`;
      }
      let fullDate = f.getFullYear() + "-" + mes + "-" + f.getDate();
      return fullDate == event.target.value;
    });

    if (event.target.value === "") {
      setPatientsDate(allArray);
      setMensaje(false);
      return;
    } else {
      setPatientsDate(newSearch);
    }
  };

  // ================ Search Contact Event =============

  const searchContactEvents = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = allArrayContact.filter(
      (item) =>
        item.user.dni.includes(searched) ||
        item.user.number.includes(searched) ||
        item.user.name.toLowerCase().includes(searched.toLowerCase()) ||
        item.user.surname.toLowerCase().includes(searched.toLowerCase())
    );
    searched.length == 0
      ? setNeedContact(allArrayContact)
      : setNeedContact(newSearch);
  };

  const handleDateSelectContact = (event) => {
    let newSearch = allArrayContact.filter((item) => {
      let f = new Date(item.createDate);
      let mes = f.getMonth() + 1;
      if (mes <= 9) {
        mes = `0${mes}`;
      }
      let fullDate = f.getFullYear() + "-" + mes + "-" + f.getDate();
      return fullDate == event.target.value;
    });
    if (event.target.value === "") {
      setNeedContact(allArrayContact);
      setMensaje(false);
      return;
    } else setNeedContact(newSearch);
  };

  // ================ EXPORT PDF =============
  const exportPDF = async(type) => {
    const exportList = type == "otherEvent" ? otherEvents : needContact
    Swal.fire({
      title: "",
      background: "transparent",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (exportList.length > 0) {
      const site = await getSite(exportList[0].cdi);
      const bodyTable = exportList.map((ev) => [
        moment.unix(ev.date / 1000).format("DD MMM YYYY"),
        ev.user?.name + " " + ev.user?.surname + " - " + ev.user?.dni,
        ev.user?.phone,
        ev.protocol?.name,
        ev.eventName,
      ]);
      const doc = new jsPDF("landscape", "px", "a4", "false");
      doc.text("Lista de Eventos - " + site.response.name, 200, 20);
      doc.autoTable({
        head: [["Fecha", "Paciente", "Contacto", "Protocolo", "Evento"]],
        body: bodyTable,
      });
      Swal.close();
      doc.save(
        "Reporte de Eventos - " + moment().format("DD MMM YYYY") + ".pdf"
      );
    }
  }
  const eventos = [
    { eventName: "Estoy Internado" },
    { eventName: "Estuve Internado" },
    { eventName: "Estoy Bien" },
    { eventName: "Perdí la Medicación" },
    { eventName: "Estoy Embarazada" },
    { eventName: "Tengo Sintomas" },
  ];

  return (
    <Container>
      <Row className="text-center divider my-4">
        <Col className="mb-2">
          <h2>Eventos</h2>
        </Col>
      </Row>
      <Row className="d-flex-event">
        <Col xs={4} className="d-flex align-items-center">
          <InputGroup>
            <input
              type="text"
              className="form-control "
              placeholder="Buscar..."
              onChange={searchOtherEvents}
            />
            <InputGroup.Text>
              <FaSistrix />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <div className="offset-md-2"></div>
        <div className="select-group-res select-last-down">
          <select className="custom-select" onChange={handleEventSelect}>
            <option defaultValue value={"no"}>
              Eventos
            </option>
            {eventos?.map((c, i) => (
              <option key={i} value={c.eventName}>
                {c.eventName}
              </option>
            ))}
          </select>
        </div>
        <div className="select-group-res select-first-down">
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onChange={handleDateSelect}
            />
          </div>
        </div>
        <div className="export-button">
          <Button
            onClick={() => exportPDF("otherEvent")}
            variant="secondary"
            className="btn-darkBlue"
            data-tip
            data-for="registerTip"
          >
            <FaDownload />
            <ReactTooltip id="registerTip" place="bottom" effect="solid">
              Descargar Lista de Eventos
            </ReactTooltip>
          </Button>
        </div>
      </Row>
      <EventoPaginate
        info={otherEvents}
        loadingInfo={loadingInfo}
        setInfo={setInfo}
        mensaje={mensaje}
      />
      <Row className="text-center divider my-4">
        <Col className="mb-2">
          <h2>Necesitan contactarse</h2>
        </Col>
      </Row>
      <Row className="d-flex-event">
        <Col xs={4} className=" d-flex align-items-center">
          <InputGroup>
            <input
              type="text"
              className="form-control "
              placeholder="Buscar..."
              onChange={searchContactEvents}
            />
            <InputGroup.Text>
              <FaSistrix />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <div className="offset-md-5"></div>
        <div className="select-group-res select-first-down">
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              onChange={handleDateSelectContact}
            />
          </div>
        </div>
        <div className="export-button mr-3">
          <Button
            onClick={() => exportPDF("needContact")}
            variant="secondary"
            className="btn-darkBlue"
            data-tip
            data-for="registerTip"
          >
            <FaDownload />
            <ReactTooltip id="registerTip" place="bottom" effect="solid">
              Descargar Lista de Eventos
            </ReactTooltip>
          </Button>
        </div>
      </Row>
      <ContactPaginate info={needContact} loadingInfo={loadingInfo} />
    </Container>
  );
}
