// const url = process.env.NODE_ENV !== "development" ? "http://vps-2265777-x.dattaweb.com/api" :"http://localhost:4000";
const url =
	process.env.NODE_ENV !== 'development'
		? 'https://api.followmed.com.ar/api'
		: 'https://api.followmed.com.ar/api';

//=================== CALENDAR ======================

export const getCalendarSite = async (id) => {
	const response = await fetch(`${url}/calendar/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	const datos = await response.json();
	return datos;
};

export const editDateCalendar = async (id, patientID, newDate, dateName) => {
	const response = await fetch(`${url}/calendar/editDate/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			patientID,
			newDate,
			dateName,
		}),
	});
	const datos = await response.json();
	return datos;
};

//================== PROTOCOLS ======================

export const getProtocols = async ({ token, id }) => {
	const response = await fetch(`${url}/protocols/list/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	});
	const datos = await response.json();
	return datos;
};

export const getProtocolsByPatient = async (token, id) => {
	const response = await fetch(`${url}/protocols/getByPatient/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	});
	const datos = await response.json();
	return datos;
};

export const createNewProtocol = async (inputValue, type, token) => {
	const response = await fetch(`${url}/protocols/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({
			type: type,
			technicalName: inputValue.technicalName,
			name: inputValue.name,
			number: inputValue.number,
			visits: inputValue.visits,
			duration: inputValue.duration,
		}),
	});
	const datos = await response.json();
	return datos;
};

export const asingProtocolToSite = async (data, id) => {
	const response = await fetch(`${url}/protocols/assignToCdi/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: data.token,
		},
		body: JSON.stringify({ cdi: data.id }),
	});
	const datos = await response.json();
	return datos;
};

export const deleteSiteProtocol = async (id, token, cdi) => {
	const response = await fetch(`${url}/protocols/deleteOfCdi/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ cdi: cdi }),
	});
	const datos = await response.json();
	return datos;
};

export const editProtocol = async (id, token, data) => {
	const response = await fetch(`${url}/protocols/edit/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify(data),
	});
	const datos = await response.json();
	return datos;
};

export const assignProtocolByPatient = async (id, token, protocol) => {
	const response = await fetch(`${url}/protocols/assign/${protocol}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({
			type: 'patients',
			user: id,
			start: new Date().valueOf(),
		}),
	});
	const datos = await response.json();
	return datos;
};

//====================== PATIENTS  ==========================

export const editPatient = async (data, token) => {
	const response = await fetch(url + '/users/editUser', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ user: data, collection: 'patients' }),
	});
	const datos = await response.json();
	return datos;
};

export const deletePatient = async (id) => {
	const response = await fetch(url + `/users/delete/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ collection: 'patients' }),
	});
	const datos = await response.json();
	return datos;
};

export const getPatientList = async (token) => {
	const patientsList = await fetch(`${url}/users/userList`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ collection: 'patients' }),
	});
	const patients = await patientsList.json();
	return patients;
};

export const getPatient = async (token, id) => {
	const patientsList = await fetch(`${url}/users/userList/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ collection: 'patients' }),
	});
	const patients = await patientsList.json();
	return patients;
};

export const getEventPatient = async (id) => {
	const patientsList = await fetch(`${url}/events/filterByPatient/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	const patients = await patientsList.json();
	return patients;
};

//=================== SITE INFO  ========================

export const getSiteTotals = async ({ id }) => {
	const response = await fetch(`${url}/general/totalsite/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	const datos = await response.json();
	return datos;
};

export const getSite = async (id, token) => {
	const response = await fetch(`${url}/users/userList/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ collection: 'cdis' }),
	});
	const datos = await response.json();
	return datos;
};

export const filterByCDI = async (cdi) => {
	const response = await fetch(`${url}/events/filterByCDI`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Authorization',
		},
		body: JSON.stringify({ cdi: cdi.id }),
	});
	let events = await response.json();
	return events.response;
};

export const editCDI = async (data, token) => {
	const response = await fetch(url + '/users/editUser', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ user: data, collection: 'cdis' }),
	});
	const datos = await response.json();
	return datos;
};
