import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import Swal from 'sweetalert2';

const AdminContext = React.createContext();

export function useAdmin() {
	return useContext(AdminContext);
}

export function AdminProvider({ children }) {
	let { currentUser } = useAuth();

	const [loadingInfo, setLoadingInfo] = useState(false);

	// const url = process.env.NODE_ENV !== "development" ? "http://vps-2265777-x.dattaweb.com/api" :"http://localhost:4000";
	const url =
		process.env.NODE_ENV !== 'development'
			? 'https://api.followmed.com.ar/api'
			: 'https://api.followmed.com.ar/api';

	const headers = {
		'Content-Type': 'application/json',
		Authorization: currentUser.token,
	};

	function getAdmins(setAllUsers, setAllArrayAdmin) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/userList`,
				{
					collection: 'admins',
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				setAllUsers(response.data.response);
				setAllArrayAdmin(response.data.response);
			})
			.catch(function (error) {
				Swal.close();
			});
	}

	function createAdmin(inputValue, newUserTipe) {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		inputValue.type = 'admin';
		inputValue.collection = 'admin';

		axios
			.post(
				`${url}/users/`,
				{
					inputValue,
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();

				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			});
	}
	const value = {
		createAdmin,
		loadingInfo,
		getAdmins,
	};

	return (
		<AdminContext.Provider value={value}>{children}</AdminContext.Provider>
	);
}
