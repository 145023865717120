import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { FaSistrix } from 'react-icons/fa';
// import PacientsForm from './PacientsForm';
import PacientesTable from './PacientesTable';

import { useCDI } from '../../Context/CDIContext';
import PaginateComponent from '../../Helpers/PaginateComponent';
import '../../Styles/CommonStyles.css';
import { getAllCDI } from '../../Conections/Admin';

export default function Pacientes() {
	const { getPatients, loadingInfo, protocolList } = useCDI();
	const [patientsList, setPatientsList] = useState([]);
	const [allArray, setAllArray] = useState([]);
	const [cdiList, setCdiList] = useState([]);
	const [patientsCDI, setPatientsCDI] = useState([]);
	const [patientsProtocol, setPatientsProtocol] = useState([]);
	const [patientsStatus, setPatientsStatus] = useState([]);
	const { token } = JSON.parse(localStorage.getItem('step_med_user'));

	useEffect(() => {
		getPatients(setPatientsList, setAllArray);
		getAllCDI(token).then((res) => {
			setCdiList(res.response);
		});
	}, []);

	useEffect(() => {
		setPatientsCDI(allArray);
		setPatientsProtocol(allArray);
		setPatientsStatus(allArray);
	}, [allArray]);

	useEffect(() => {
		const patients = [];
		for (const patient of allArray) {
			if (
				patientsStatus.indexOf(patient) > -1 &&
				patientsCDI.indexOf(patient) > -1 &&
				patientsProtocol.indexOf(patient) > -1
			) {
				patients.push(patient);
			}
		}
		setPatientsList(patients);
	}, [patientsProtocol, patientsStatus, patientsCDI]);

	const search = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter(
			(item) => item.number.includes(searched) || item.dni.includes(searched)
		);
		setPatientsList(newSearch);
	};

	const handleCDISelect = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter((item) => item.cdi.includes(searched));
		setPatientsCDI(newSearch);

		if (searched == 'no') setPatientsCDI(allArray);
	};

	const handleProtocolSelect = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter((item) =>
			item.protocol?.number.includes(searched)
		);
		setPatientsProtocol(newSearch);

		if (searched == 'no') setPatientsProtocol(allArray);
	};

	const handleStatusSelect = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter((item) => item.status.includes(searched));
		setPatientsStatus(newSearch);

		if (searched == 'no') setPatientsStatus(allArray);
	};

	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 5;
	const offset = currentPage * PER_PAGE;
	const pageCount = Math.ceil(patientsList?.length / PER_PAGE);
	const currentPageData = patientsList?.slice(offset, offset + PER_PAGE);

	return (
		<div className='backgroudCommon'>
			<Container>
				<Row className='text-center divider pb-4 mb-3 mt-3'>
					<Col className='mb-2'>
						<h2>Pacientes</h2>
					</Col>
				</Row>
				<Row className='pacientes-row-res'>
					<Col xs={4} className='col-4-res-paciente'>
						<InputGroup>
							<input
								type='text'
								className='form-control '
								placeholder='Buscar...'
								onChange={search}
							/>
							<InputGroup.Text>
								<FaSistrix />
							</InputGroup.Text>
						</InputGroup>
					</Col>

					<div className='select-group-res select-last-down'>
						<select className='custom-select' onChange={handleCDISelect}>
							<option defaultValue value={'no'}>
								CDI
							</option>
							{cdiList?.map((c, i) => (
								<option key={i} value={c.id}>
									{c.name_site}
								</option>
							))}
						</select>
					</div>
					<div className='select-group-res select-second-down'>
						<select className='custom-select' onChange={handleProtocolSelect}>
							<option defaultValue value={'no'}>
								Protocolo
							</option>
							{protocolList?.map((p, i) => (
								<option key={i} value={p.number}>
									{p.name} - {p.number}
								</option>
							))}
						</select>
					</div>
					<div className='select-group-res select-first-down'>
						<select className='custom-select' onChange={handleStatusSelect}>
							<option defaultValue value='no'>
								Estado
							</option>
							<option value='green'>Verde</option>
							<option value='yellow'>Amarillo</option>
							<option value='red'>Rojo</option>
							<option value='cyan'>Azul</option>
						</select>
					</div>
				</Row>
				<PacientesTable
					patientsList={currentPageData}
					loadingInfo={loadingInfo}
				/>
				<PaginateComponent
					pageCount={pageCount}
					currentPageData={currentPageData}
					setCurrentPage={setCurrentPage}
				/>
			</Container>
		</div>
	);
}
