import moment from "moment";
import { React } from "react";
import { Button, Row, Table } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import 'moment/locale/es';
moment.locale('es');

export default function EventosCDITable({
  info,
  loadingInfo,
  mensaje,
}) {
  
  const openObservation = (info) => {
    console.log("::")
    Swal.fire({
      text: info.additionalData
    })
  }

  return (
		<>
			<Row className='justify-content-around mt-1 p-3'>
				<div className='headerTable'>Lista de Eventos </div>
				<div className='m-auto'>{loadingInfo ? 'Cargando' : ''}</div>
				<div className='m-auto'>{mensaje ? 'No hay resultados' : ''}</div>
				<div className='table-responsive customTables'>
					<Table className='table-bordered m-0' size='sm'>
						<thead>
							<tr>
								<th className='text-center'>Fecha</th>
								<th className='text-center'>Inicio de Evento</th>
								<th className='text-center'>Fin de Evento</th>
								<th className='text-center'>Paciente</th>
								<th className='text-center'>DNI</th>
								<th className='text-center'>Contacto</th>
								<th className='text-center'>Protocolo</th>
								<th className='text-center'>Evento</th>
							</tr>
						</thead>
						<tbody>
							{info?.length === 0 || !info ? (
								<td colSpan={12} className='text-center'>
									No hay Eventos
								</td>
							) : (
								info?.map((info, i) => {
									return (
										<tr key={i}>
											<td className='text-center'>{moment.unix(info?.createDate / 1000).format('DD MMM YYYY')}</td>
											<td className='text-center'>{moment.unix(info.date / 1000).format('DD MMM YYYY')}</td>
											<td className='text-center'>{info.endDate !== 'none'
                        ? moment.unix(info.endDate / 1000).format('DD MMM YYYY')
                        : moment.unix(info.date / 1000).format('DD MMM YYYY')
                      }</td>
											<td className='text-center'>
												{info.user?.surname} {info.user?.name}
											</td>
											<td className='text-center'>{info.user?.dni}</td>
											<td className='text-center'>{info.user?.phone}</td>
											<td className='text-center'>{info.protocol?.name}</td>
											<td className='text-center'>{info.eventName}</td>
											{info.additionalData !== 'none' ? (
												<td className='text-center' onClick={() => openObservation(info)} data-tip data-for='registerTip'>
													<FaInfoCircle />
													<ReactTooltip id='registerTip' place='bottom' effect='solid'>
														Ver Observaciones
													</ReactTooltip>
												</td>
											) : (
												<td></td>
											)}
										</tr>
									);
								})
							)}
						</tbody>
					</Table>
				</div>
			</Row>
		</>
	);
}
