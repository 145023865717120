const phoneAreas = [
	{
		country: 'Argentina',
		code: 54,
	},
	{
		country: 'Bolivia',
		code: 591,
	},
	{
		country: 'Colombia',
		code: 57,
	},
	{
		country: 'Ecuador',
		code: 593,
	},
	{
		country: 'Peru',
		code: 51,
	},
	{
		country: 'Chile',
		code: 56,
	},
	{
		country: 'España',
		code: 34,
	},
	{
		country: 'Mexico',
		code: 52,
	},
	{
		country: 'Panama',
		code: 507,
	},
	{
		country: 'Paraguay',
		code: 595,
	},
	{
		country: 'Uruguay',
		code: 598,
	},
	{
		country: 'Venezuela',
		code: 58,
	},
];

export { phoneAreas };
