import React, { useEffect, useState } from "react";
import TableProfileCDI from "./Components/TableProfileCDI";
import Swal from "sweetalert2";
import { Col, Container, Row } from "react-bootstrap";
import { useCDI } from "../../Context/CDIContext";
import { getSite } from "../../Conections/Sites";

export default function ProfileCDI() {
  const { loadingInfo } = useCDI();
  const { id, token } = JSON.parse(localStorage.getItem("step_med_user")) || "";
  const [user, setUser] = useState({
    address: "",
    address_site: "",
    email: "",
    id: "",
    n_protocol: 0,
    name: "",
    name_site: "",
    phone: "",
    phone_site: "",
    protocol: [],
    status: "",
    surname: "",
    type: "",
  });

  useEffect(() => {
    if (id) {
      Swal.fire({
        title: "",
        allowEscapeKey: false,
        allowOutsideClick: false,
        background: "transparent",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      getSite(id, token)
        .then((resp) => {
          console.log(resp);
          setUser(resp?.response);
          Swal.close();
        })
        .catch((err) => {
          Swal.close();
        });
    } else {
      alert("no hay id");
    }
  }, []);

  return (
    <div className="backgroudCommon">
      <Container>
        {loadingInfo && <div className="text-center">Cargando...</div>}
        <TableProfileCDI user={user} setUser={setUser} />
      </Container>
    </div>
  );
}
