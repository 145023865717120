import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, InputGroup } from "react-bootstrap";
import "../../Styles/ModalCDI.css";
import "../../Styles/CommonStyles.css";
import "../../Styles/PacientesComponent.css";
import ReactTooltip from "react-tooltip";
import { FaDownload } from "react-icons/fa";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import {
  getEventPatient,
  getProtocolsByPatient,
  editPatient,
} from "../../Conections/Sites";

export default function ModalPatientsData({
  setMoreDataShow,
  totalInfo,
  listPatient,
}) {
  const [events, setEvents] = useState();
  const [eventsToday, setEventsToday] = useState([]);
  const [protocolAssign, setProtocolAssign] = useState();
  const [dates, setDates] = useState([]);
  const [observations, setObservations] = useState(totalInfo?.observations);
  const { token } = JSON.parse(localStorage.getItem("step_med_user"));

  useEffect(() => {
    getEventPatient(totalInfo.id).then((res) => {
      setEvents(res.response);
    });
  }, []);

  useEffect(() => {
    getProtocolsByPatient(token, totalInfo.id).then((res) => {
      if (res?.success) {
        const protocol = res.response[0];
        let duration = 0;
        for (const item of totalInfo.protocol.duration) {
          duration += parseInt(item.frequency);
        }
        protocol.duration = duration;
        protocol.start = moment
          .unix(protocol.start / 1000)
          .format("DD/MM/YYYY");
        setProtocolAssign(protocol);
      }
    });
  }, []);

  useEffect(() => {
    const eventsList = [];
    if (events) {
      for (const event of events) {
        if (
          moment.unix(event.createDate / 1000).format("DD/MM/YYYY") ==
          moment().format("DD/MM/YYYY")
        ) {
          eventsList.push(event);
        }
      }
      console.log(eventsList);
      setEventsToday(eventsList);
    }
  }, [events]);

  const visitiControl = (schedule) => {
    if (schedule) {
      const dates = [];
      for (const date of schedule) {
        if (date.date >= new Date().valueOf()) {
          dates.push(date.date);
        }
      }
      return dates;
    } else return false;
  };

  useEffect(() => {
    const dates = visitiControl(protocolAssign?.schedule);
    setDates(dates);
  }, [protocolAssign]);

  function exportPDF() {
    Swal.fire({
      title: "",
      background: "transparent",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const allDates = protocolAssign.schedule.map((turno) =>
      moment(turno.date).format("DD/MM/YYYY")
    );
    const dates = [];
    for (let i = 0; i < allDates.length; i += 6) {
      const partDate = allDates.slice(i, i + 6);
      dates.push(partDate);
    }
    const pdf = new jsPDF();
    pdf.text("Reporte - " + totalInfo.surname + " " + totalInfo.name, 14, 20);
    pdf.line(14, 22, 200, 22);
    pdf.autoTable({
      startY: 25,
      head: [["Dni", "Teléfono", "Email", "Dirección"]],
      body: [
        [totalInfo.dni, totalInfo.phone, totalInfo.email, totalInfo.address],
      ],
    });
    pdf.autoTable({
      head: [
        [
          "Protocolo Asignado",
          "Fecha de Asignación",
          "Duración del Tratamiento",
        ],
      ],
      body: [
        [
          totalInfo.protocol?.name,
          protocolAssign?.start,
          protocolAssign?.duration + " días",
        ],
      ],
    });
    pdf.autoTable({ theme: "plain", head: [["Fechas de Turnos"]] });
    pdf.autoTable({
      body: dates,
    });
    pdf.autoTable({ theme: "plain", head: [["Eventos"]] });
    pdf.autoTable({
      head: [["Fecha", "Evento"]],
      body: events.map((ev) => [
        moment(ev.date).format("DD/MM/YYYY"),
        ev.eventName,
      ]),
    });
    pdf.autoTable({ theme: "plain", head: [["Observaciones"]] });
    pdf.autoTable({
      body: [[observations]],
    });
    Swal.close();
    pdf.save(
      "Reporte " +
        totalInfo.surname +
        " " +
        totalInfo.name +
        " - " +
        moment().format("DD MMM YYYY") +
        ".pdf"
    );
  }
  const handleChange = (event) => {
    setObservations(event.target.value);
  };

  const addObservations = () => {
    const data = {
      id: totalInfo.id,
      observations: observations,
    };
    Swal.fire({
      title: "",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    editPatient(data, token).then((res) => {
      Swal.close();
      if (res.success) {
        Swal.fire({
          title: "Exito!",
          text: "Se guardó exitosamente",
          icon: "success",
          confirmButtonText: "Cerrar",
        }).then(() => {
          listPatient();
        });
      } else {
        Swal.fire({
          title: "Oh No!",
          text: res.message,
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    });
  };

  return (
    <div className='Modal'>
      <div className='Modal__Container-patient'>
        <div className='modal-dialog'>
          <div className='modal-content  h-patient-modal'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Paciente
              </h5>
              <button
                onClick={() => setMoreDataShow(false)}
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='pt-4-res-p px-5 d-flex w-100 justify-content-between flex-res-p'>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Nombre:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.name || "-"}
                </div>
              </div>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Apellido:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.surname || "-"}
                </div>
              </div>
            </div>
            <div className='pt-4-res-p px-5 d-flex w-100 justify-content-between flex-res-p'>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Email:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.email || "-"}
                </div>
              </div>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Telefono:</b>
                </label>
                <div className='patient-data-container w-100'>
                  (+{totalInfo?.phone_area}) {totalInfo.phone || "-"}
                </div>
              </div>
            </div>
            <div className='pt-4-res-p px-5 d-flex w-100 justify-content-between flex-res-p'>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Dni:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.dni || "-"}
                </div>
              </div>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Dirección:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.address || "-"}
                </div>
              </div>
            </div>
            <div className='pt-4-res-p px-5 d-flex w-100 justify-content-between flex-res-p'>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Protocolo asignado el:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {protocolAssign?.start || "-"}
                </div>
              </div>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Duracion del tratamiento:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {protocolAssign?.duration || "-"} días
                </div>
              </div>
            </div>
            <div className='pt-4-res-p px-5 d-flex w-100 justify-content-between flex-res-p'>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Protocolo:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo?.protocol?.name || "-"}
                </div>
              </div>
              <div className='d-flex justify-content-center flex-column align-items-center w-50-res-p px-2'>
                <label className='align-self-baseline'>
                  <b>Numero de paciente:</b>
                </label>
                <div className='patient-data-container w-100'>
                  {totalInfo.number || "-"}
                </div>
              </div>
            </div>
            <div className='d-flex flex-column pt-4 px-5 w-100'>
              <label className='align-self-baseline'>
                <b>Visitas restantes:</b>
              </label>
              <div className='patient-turns-table d-flex justify-content-center align-self-center mt-2 table-max-over'>
                <table className='w-100 d-flex flex-column align-items-center'>
                  <thead className='turns-table-th w-100 justify-content-center d-flex'>
                    <tr>
                      <th>Fechas de turnos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dates.length > 0 ? (
                      dates
                        .sort((a, b) => a - b)
                        .map((date) => {
                          return (
                            <tr>
                              <td>
                                <i>{moment(date).format("DD/MM/YYYY")}</i>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <td>
                        <i> No hay visitas programadas </i>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='mt-3 px-3'>
              <label className='align-self-baseline'>
                <b>Eventos registrados</b>
              </label>
              <div className='events-border table-max-over'>
                <table className='w-100'>
                  <thead className='thead-patients-event'>
                    <tr>
                      <th className='events-border-r text-center'>Fecha</th>
                      <th className='events-border-r text-center'>
                        Fecha de Evento
                      </th>
                      <th className='events-border-r text-center'>Evento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events?.length ? (
                      events?.map((e) => (
                        <tr>
                          <td className='events-border-b text-center'>
                            {moment
                              .unix(e.createDate / 1000)
                              .format("DD/MM/YYYY")}
                          </td>
                          <td className='events-border-b text-center'>
                            {moment.unix(e.date / 1000).format("DD/MM/YYYY")}
                          </td>
                          <td className='events-border-r text-center'>
                            {e.eventName}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className='events-border-r text-center'>
                          Sin Eventos Registrados
                        </td>
                        <td className='events-border-b text-center'>-</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <label className='align-self-baseline mt-3'>
                <b>Evento registrado hoy</b>
              </label>
              <div className='events-border table-max-over text-center'>
                {eventsToday?.length > 0 ? (
                  <>
                    <table className='w-100'>
                      <thead className='thead-patients-event'>
                        <tr>
                          <th className='events-border-r text-center'>
                            Fecha de Evento
                          </th>
                          <th className='events-border-r text-center'>
                            Evento
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventsToday.map((e) => (
                          <tr>
                            <td className='events-border-b text-center'>
                              {moment(e.date).format("DD/MM/YYYY")}
                            </td>
                            <td className='events-border-r text-center'>
                              {e.eventName}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  `Sin eventos el día de la fecha`
                )}
              </div>
              <div className='my-4 d-flex flex-column'>
                <label className='align-self-baseline'>
                  <b>Observaciones</b>
                </label>
                <textarea
                  className='patient-data-container'
                  style={{ "min-height": "9rem", "max-height": "413px" }}
                  name='observations'
                  onChange={handleChange}
                  defaultValue={totalInfo?.observations}
                ></textarea>
                <div>
                  <Row className='d-flex-event'>
                    <Col xs={8} className='d-flex align-items-center'>
                      <Button
                        onClick={exportPDF}
                        variant='secondary'
                        className='btn-darkBlue'
                        data-tip
                        data-for='registerTip'
                      >
                        <FaDownload />
                        <ReactTooltip
                          id='registerTip'
                          place='bottom'
                          effect='solid'
                        >
                          Descargar Detalles
                        </ReactTooltip>
                      </Button>
                    </Col>
                    <div className='align-self-end mt-3'>
                      <Button
                        variant='outline-warning mx-2'
                        onClick={addObservations}
                      >
                        Guardar observaciones
                      </Button>
                      <Button
                        variant='secondary mx-2'
                        onClick={() => setMoreDataShow(false)}
                      >
                        Cerrar
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
