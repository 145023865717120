import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, InputGroup } from "react-bootstrap";
import { FaDownload, FaSistrix } from "react-icons/fa";
import EventTable from "./EventsComponents/EventTable";
import { useCDI } from "../../Context/CDIContext";
import PaginateComponent from "../../Helpers/PaginateComponent";
import { getAllCDI } from "../../Conections/Admin";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function Eventos() {
  const { getEvents, loadingInfo, eventList } = useCDI();
  const [allArray, setAllArray] = useState([]);
  const [cdiList, setCdiList] = useState([]);
  const [patientsCDI, setPatientsCDI] = useState([]);
  const [patientsEvent, setPatientsEvent] = useState([]);
  const [patientsDate, setPatientsDate] = useState([]);
  const { token } = JSON.parse(localStorage.getItem("step_med_user"));

  useEffect(() => {
    getEvents(setAllArray);
    getAllCDI(token).then((res) => {
      setCdiList(res.response);
    });
  }, []);

  useEffect(() => {
    setPatientsCDI(eventList)
    setPatientsEvent(eventList)
    setPatientsDate(eventList)
  }, [eventList])

  useEffect(() => {
    const patients = [];
    for (const patient of eventList) {
      if (patientsEvent.indexOf(patient) > -1 && patientsCDI.indexOf(patient) > -1 && patientsDate.indexOf(patient) > -1) {
        patients.push(patient)
      }
    }
    setAllArray(patients)
  }, [patientsDate, patientsEvent, patientsCDI])

  function search(event) {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = eventList.filter(
      (item) =>
        item.user.number.includes(searched) ||
        item.cdi.name_site.toLowerCase().includes(searched.toLowerCase()) ||
        item.protocol.name.toLowerCase().includes(searched.toLowerCase()) ||
        item.protocol.number.includes(searched) ||
        item.eventName.toLowerCase().includes(searched.toLowerCase())
    );
    setAllArray(newSearch);
  }

  const handleCDISelect = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = eventList.filter((item) => item.cdi.id.includes(searched));
    searched == "no" ? setPatientsCDI(eventList) : setPatientsCDI(newSearch);
  };

  const handleEventSelect = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = eventList.filter((item) =>
      item.eventName.includes(searched)
    );
    searched == "no" ? setPatientsEvent(eventList) : setPatientsEvent(newSearch);
  };

  const handleDateSelect = (event) => {
    event.preventDefault();
    const searched = event.target.value;
    let newSearch = eventList.filter((item) => {
      let f = new Date(item.date);
      let mes = f.getMonth() + 1;
      if (mes <= 9) {
        mes = `0${mes}`;
      }
      let fullDate = f.getFullYear() + "-" + mes + "-" + f.getDate();
      return fullDate == searched;
    });
    searched == "" ? setPatientsDate(eventList) : setPatientsDate(newSearch);
  };
  const eventos = [
    { eventName: "Estoy Internado" },
    { eventName: "Estuve Internado" },
    { eventName: "Estoy Bien" },
    { eventName: "Perdí la Medicación" },
    { eventName: "Estoy Embarazada" },
    { eventName: "Tengo Sintomas" },
    { eventName: "Necesito Contactarme" },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(allArray?.length / PER_PAGE);
  const currentPageData = allArray?.slice(offset, offset + PER_PAGE);

  const exportPDF = () => {
    Swal.fire({
      title: "",
      background: "transparent",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (allArray.length > 0) {
      const bodyTable = allArray.map((ev) => [
        moment.unix(ev.date / 1000).format("DD MMM YYYY"),
        ev.user?.number,
        ev.cdi?.name_site,
        ev.protocol?.name,
        ev.eventName,
      ]);
      const doc = new jsPDF("landscape", "px", "a4", "false");
      doc.text("Lista de Eventos", 280, 20);
      doc.autoTable({
        head: [["Fecha", "Paciente", "CDI", "Protocolo", "Evento"]],
        body: bodyTable,
      });
      Swal.close();
      doc.save(
        "Reporte de Eventos - " + moment().format("DD MMM YYYY") + ".pdf"
      );
    }
  }
  return (
    <div className="backgroudCommon">
      <Container>
        <Row className="text-center divider pt-4 mb-4">
          <Col className="mb-2">
            <h2>Eventos</h2>
          </Col>
          <div className="export-button">
            <Button
              onClick={exportPDF}
              variant="secondary"
              className="btn-darkBlue"
              data-tip
              data-for="registerTip"
            >
            <FaDownload />
            <ReactTooltip id="registerTip" place="bottom" effect="solid">
              Descargar Lista de Eventos
            </ReactTooltip>
          </Button>
        </div>
        </Row>
        <Row className="pacientes-row-res">
          <Col xs={4} className="d-flex align-items-center col-4-res-paciente">
            <div className="input-group">
              <InputGroup>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Buscar..."
                  onChange={search}
                />
                <InputGroup.Text>
                  <FaSistrix />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Col>
          <div className="select-group-res select-last-down">
            <select className="custom-select" onChange={handleEventSelect}>
              <option defaultValue value={"no"}>
                Eventos
              </option>
              {eventos?.map((c, i) => (
                <option key={i} value={c.eventName}>
                  {c.eventName}
                </option>
              ))}
            </select>
          </div>
          <div className="select-group-res select-second-down">
            <select className="custom-select" onChange={handleCDISelect}>
              <option defaultValue value={"no"}>
                CDI
              </option>
              {cdiList?.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.name_site}
                </option>
              ))}
            </select>
          </div>
          <div className="select-group-res select-first-down">
            <div class="input-group">
              <input
                type="date"
                class="form-control"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={handleDateSelect}
              />
            </div>
          </div>
        </Row>
        <EventTable loadingInfo={loadingInfo} info={currentPageData} />
        <PaginateComponent
          pageCount={pageCount}
          currentPageData={currentPageData}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  );
}
