import moment from "moment";
import { React } from "react";

function GetTurns() {
  const Turnos = (diaN, res, editModalShow) => {
    let emptyCol = false;
    const turnosDelDia = res.filter((d) => {
      const formatted = moment.utc(d?.date).format("DD/MM/YYYY");
      return formatted === diaN;
    });

    if (turnosDelDia.length === 0 && emptyCol === false) {
      emptyCol = true;
      return (
        <div className='no-turn d-flex justify-content-center align-items-center calendar-item'>
          NO hay turnos este dia!
        </div>
      );
    } else {
      const ret = turnosDelDia.map((t) => {
        return (
          <div
            className='turn d-flex flex-column justify-content-between p-2 calendar-item'
            onClick={() => editModalShow(t)}
            style={{ cursor: "pointer" }}
          >
            <h5 className='pl-2 mb-0'>
              <b>{t.user}</b>
            </h5>
            <div>
              Paciente: <b>{t.patient?.name}</b>
            </div>
            <div>
              Protocolo: <b>{t.protocol}</b>
            </div>
          </div>
        );
      });
      return ret;
    }
  };
  return {
    Turnos,
  };
}

export default GetTurns;
