import React, { useState } from "react";
import { Navbar, Button, Nav, Alert, Col, Row } from "react-bootstrap";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { FaSignOutAlt } from "react-icons/fa";
import logo from "./../../Img/BLANCO.png";
export default function NavBarCDI() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setError("");
    try {
      await logout();
      history.push("/login");
    } catch (error) {
      setError("Error al deslogear");
    }
  }

  let { url } = useRouteMatch();

  return (
    <>
      <Navbar className="Main_Navbar shadow p-0" variant="light" expand="md">
        <div className="justify-content-between w-100 nav-toggle">
          <Nav.Link
            as={Link}
            className="navbar-brand display-flex-nav align-items-center"
            to="/"
          >
            <img src={logo} alt="a" style={{ "max-width": "3rem" }} />
          </Nav.Link>
          <Nav.Link
            as={Link}
            className="navbar-brand display-flex-nav align-items-center d-flex"
            to="/"
          >
            <h3 className="text-white">
              <b>FollowMed</b>
            </h3>
          </Nav.Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="my-2 mr-2"
          />
        </div>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className=" display-flex-nav justify-content-center"
        >
          <div className="w-100 d-flex flex-row-navbar">
            <div className="display-flex-nav w-50-nav px-2-nav">
              <Nav className="navbar-size align-items-center">
                <Nav.Link
                  as={Link}
                  className="navbar-brand display-flex-nav align-items-center"
                  to="/"
                >
                  <img src={logo} alt="a" style={{ "max-width": "3rem" }} />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="navbar-link h-100 display-flex-nav align-items-center"
                  to={`${url}`}
                  style={{ color: "white" }}
                >
                  Inicio
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="navbar-link h-100 display-flex-nav align-items-center"
                  to={`/pacientescdi`}
                  style={{ color: "white" }}
                >
                  Pacientes
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="navbar-link h-100 display-flex-nav align-items-center"
                  to={`${url}eventoscdi`}
                  style={{ color: "white" }}
                >
                  Eventos
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="navbar-link h-100 display-flex-nav align-items-center"
                  to={`${url}calendarcdi`}
                  style={{ color: "white" }}
                >
                  Turnos
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="navbar-link h-100 display-flex-nav align-items-center"
                  to={`${url}perfilcdi`}
                  style={{ color: "white" }}
                >
                  Perfil
                </Nav.Link>
              </Nav>
            </div>

            <div className="d-flex justify-content-end-nav w-50-nav px-2">
              <Row className="m-0">
                <Col xs={12} className="d-flex justify-content-center">
                  <div className="ml-auto text-white">
                    Hola {currentUser.nombre}
                  </div>
                </Col>
                <Col xs={12} className="d-flex justify-content-end-nav">
                  <button
                    className="btn-icon btn-darkBlue p-1 mb-1 d-flex justify-content-around align-items-center"
                    onClick={handleLogout}
                  >
                    <FaSignOutAlt />
                    <div>Cerrar sesión</div>
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
      {error && <Alert variant="danger">{error}</Alert>}
    </>
  );
}
