import moment from "moment";
import React from "react";
import { Col, Row, Table, Button, InputGroup } from "react-bootstrap";
import { FaSistrix, FaPlusCircle, FaRegTrashAlt } from "react-icons/fa";
import { deleteUser } from '../../Conections/Admin';
import Swal from 'sweetalert2';
import "../../Styles/CommonStyles.css";

export default function AdminTable({
  openModal,
  allUsers,
  loadingInfo,
  handleSearchAdmin,
  userType
}) {

  const confimrDelete = (user) => {
		Swal.fire({
			title: '¿Eliminar usuario?',
			text: `Se eliminará usuario ${user.surname} ${user.name}!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
          deleteUser(user.id, 'admins').then((res) => {
            if (res.success) {
              window.location.reload();
              Swal.fire({
                title: 'Exito!',
                text: 'Se eliminó exitosamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
              });
					} else {
						Swal.fire({
							title: 'Oh No!',
							text: res.message,
							icon: 'error',
							confirmButtonText: 'Cerrar',
						});
					}
				});
			}
		});
	};

  return (
    <>
      <Row className='text-center divider my-4'>
        <Col className='mb-2'>
          <h2>Usuarios Admin</h2>
        </Col>
      </Row>
      <Row className='align-items-center'>
        <Col xs={5} className='no-col'>
          <InputGroup>
            <input
              type='text'
              className='form-control '
              placeholder='Buscar...'
              onChange={handleSearchAdmin}
            />
            <InputGroup.Text>
              <FaSistrix />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs={3} className='btn-t-3-res no-col'>
          <button
            className='text-center w-75-res-user btn-padding btn-darkBlue'
            onClick={() => openModal("admin")}
          >
            Nuevo Usuario <FaPlusCircle />
          </button>
        </Col>
      </Row>
      <Row className='justify-content-around mt-1 p-3'>
        <div className='headerTable'>Lista de Admins </div>
        {loadingInfo && <div className='text-center'>Cargando...</div>}
        <div className='table-responsive customTables'>
          <Table hover className='table-bordered m-0' size='sm'>
            <thead>
              <tr>
                <th className='text-center'>Usuario</th>
                <th className='text-center'>Dirección</th>
                <th className='text-center'>Teléfono</th>
                <th className='text-center'>E-Mail</th>
                <th className='text-center'>Ultima conexión</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((user, i) => {
                if (user.type === "admin" || "superAdmin") {
                  return (
										<tr key={i}>
											<td className='text-center'>
												{user.surname} {user.name}
											</td>
											<td className='text-center'>{user.address}</td>
											<td className='text-center'>{user.phone}</td>
											<td className='text-center'>{user.email}</td>
											<td className='text-center'>{user.lastConnection ? moment(user.lastConnection).format('DD/MM/YYYY hh:mm A') : ''}</td>
											{userType === 'superAdmin' && user.type !== "superAdmin" ? (
												<td className='text-center delet text-secondary'>
													<FaRegTrashAlt onClick={() => confimrDelete(user)} />
												</td>
											) : null}
										</tr>
									);
                }
                return true;
              })}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
}
