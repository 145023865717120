// const url = process.env.NODE_ENV !== "development" ? "http://vps-2265777-x.dattaweb.com/api" :"http://localhost:4000";
const url =
	process.env.NODE_ENV !== 'development'
		? 'https://api.followmed.com.ar/api'
		: 'https://api.followmed.com.ar/api';

export const getTotalInfo = async (data, id) => {
	const response = await fetch(`${url}/general/total`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	const datos = await response.json();
	return datos;
};

export const handleRegist = async (data, token) => {
	const response = await fetch(`${url}/users/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify(data),
	});
	const datos = await response.json();
	return datos;
};

export const getAllCDI = async (token) => {
	const response = await fetch(`${url}/users/userList`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({ collection: 'cdis' }),
	});
	const datos = await response.json();
	return datos;
};

export const deleteUser = async (id, collection) => {
	const response = await fetch(`${url}/users/delete/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ collection: collection }),
	});
	const datos = await response.json();
	return datos;
};

export const deleteProtocol = async (id, token) => {
	const response = await fetch(`${url}/protocols/delete/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
	});
	const datos = await response.json();
	return datos;
};

export const createNewPatient = async (data, cdiID, token) => {
	const response = await fetch(`${url}/users/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: token,
		},
		body: JSON.stringify({
			name: data.name,
			surname: data.surname,
			email: data.email,
			address: data.address,
			phone: data.phone,
			phone_area: data.phone_area,
			dni: data.dni,
			contact_phone: data.contact_phone,
			family_email: data.family_email,
			password: data.password,
			cdi: cdiID,
			type: 'patient',
			protocol: [],
			n_protocol: 0,
			status: 'cyan',
			number: data.number,
		}),
	});
	const datos = await response.json();
	return datos;
};
