import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Row, Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import '../Styles/LoginRoute.css';


export default function ResetPassword() {


  const [validated, setValidated] = useState(false);
  const [inputEmail, setInputEmail] = useState({
    email: ""
  });
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("")
  const { resetPassword } = useAuth()


  useEffect(() => {

  }, [inputEmail]);

  const handleChange = (event) => {
    setInputEmail({
      ...inputEmail,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    setMensaje("")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (inputEmail.email === "") {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      event.preventDefault();
      try {
        await resetPassword(inputEmail.email)
        setMensaje("Revisa tu correo para reiniciar tu contraseña")
      } catch (error) {
        setError("El email ingresado no existe")
      }
    }
  };

  return (
    <Container className="blur-l">
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col xs={10}>
          <div className="card-login o-hidden border-0 my-5">
            <div className="card-body-login p-0">
              <Row className="same-height-sing">
                <Col lg={6} className="d-lg-block bg-login-image" ></Col>
                <Col lg={6}>
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">¡Bienvenido a <b>FollowMed!</b></h1>
                    </div>
                    <div>
                    <Form
                      noValidate
                      validated={validated}
                      className="h-100"
                      onSubmit={handleSubmit}
                    >{error && <Alert variant="danger">{error}</Alert>} 
                    {mensaje && <Alert variant="success">{mensaje}</Alert>} 
                      <Form.Group className="mt-2" controlId="validationCustom01">
                        <Form.Control
                          onChange={handleChange} 
                          type="email" 
                          placeholder="Enter email" 
                          name='email'
                          className="inputCommon"
                          required
                        />
                        <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Por Favor complete el campo correctamente
                        </Form.Control.Feedback>
                      </Form.Group>
                      

                      <Form.Group className="d-flex justify-content-around align-items-center">
                        

                          <Button className="mt-4 mb-3 w-75" type="submit" variant="outline-primary">
                            Restablecer contraseña
                          </Button>
                          
                        
                      </Form.Group>
                      
                      <Link to="/login">Volver al inicio de sesión</Link>
                    </Form>
                    </div>
                    <hr />
                    <div></div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

