import React from "react";
import ProtocolForm from "./HomeComponents/ProtocolForm";
import { useCDI } from "../../Context/CDIContext";
import "../../Styles/Modal.css";

export default function ModalProtocol({
  setOpenModalProtocol,
  type,
  setUpdate,
}) {
  const { createProtocol } = useCDI();

  return (
    <div className="Modal">
      <div className="Modal__Container" style={{"top":"100px"}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Nuevo Protocolo
              </h5>
              <button
                onClick={() => setOpenModalProtocol(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ProtocolForm
                createProtocol={createProtocol}
                type={type}
                setOpenModalProtocol={setOpenModalProtocol}
                setUpdate={setUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
