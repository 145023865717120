import { React, useState } from 'react';
import { Table, Row, Col, InputGroup } from 'react-bootstrap';
import ModalEditProtocol from '../../../Helpers/ModalEditProtocol';
import Swal from 'sweetalert2';

import {
	FaSistrix,
	FaPlusCircle,
	FaPencilAlt,
	FaRegTrashAlt,
} from 'react-icons/fa';
import '../../../Styles/CommonStyles.css';
import { deleteProtocol } from '../../../Conections/Admin';

export default function ProtocolTable({ info, setOpenModalProtocol, search }) {
	const [editModalProtocol, setEditModalProtocol] = useState(false);
	const [infoProtocolEdit, setInfoProtocolEdit] = useState(0);

	const { token } = JSON.parse(localStorage.getItem('step_med_user'));

	const handleOpenEditModal = (info) => {
		setInfoProtocolEdit(info);
		setEditModalProtocol(true);
	};

	const confimrDelete = (info) => {
		Swal.fire({
			title: '¿Eliminar Protocolo?',
			text: `Se eliminará el prótocolo ${info.name} (${info.number})!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: '',
					allowEscapeKey: false,
					allowOutsideClick: false,
					background: 'transparent',
					showConfirmButton: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				deleteProtocol(info.n_protocol, token).then((res) => {
					Swal.close();
					if (res.success) {
						Swal.fire({
							title: 'Exito!',
							text: 'Se eliminó exitosamente',
							icon: 'success',
							confirmButtonText: 'Cerrar',
						}).then(() => window.location.reload());
					} else {
						Swal.fire({
							title: 'Oh No!',
							text: 'No se pudo eliminar el protocolo',
							icon: 'error',
							confirmButtonText: 'Cerrar',
						});
					}
				});
			}
		});
	};

	return (
		<>
			<Row className='text-center divider my-4'>
				<Col className='mb-2'>
					<h2>Protocolos</h2>
				</Col>
			</Row>
			<div className='mt-5 w-100 d-flex in-but-pos-res'>
				<div className='inicio-btn-site w-40-res'>
					<div className='input-group'>
						<InputGroup>
							<input
								type='text'
								className='form-control '
								placeholder='Buscar...'
								onChange={search}
							/>
							<InputGroup.Text>
								<FaSistrix />
							</InputGroup.Text>
						</InputGroup>
					</div>
				</div>
				<div className='new-protocol-res inicio-btn-site m-res-900'>
					<button
						className='text-center btn-padding w-100 btn-darkBlue'
						onClick={() => setOpenModalProtocol(true)}
					>
						Nuevo Protocolo <FaPlusCircle />
					</button>
				</div>
			</div>
			<Row className='justify-content-around mt-1 p-3'>
				<div className='headerTable'>Lista de Protocolos </div>
				<div className='table-responsive customTables'>
					<Table hover className='table-bordered m-0' size='sm'>
						<thead>
							<tr>
								<th className='text-center mx-3' rowSpan={3}>
									Nombre
								</th>
								<th className='text-center mx-3' rowSpan={3}>
									Acrónimo
								</th>
								<th className='text-center mx-3' rowSpan={3}>
									N de protocolo
								</th>
							</tr>
						</thead>
						<tbody>
							{info &&
								info.map((info, i) => (
									<tr key={i}>
										<td className='text-center mx-3'>{info.technicalName}</td>
										<td className='text-center mx-3'>{info.name}</td>
										<td className='text-center mx-3'>{info.number}</td>
										<td className='text-center mx-3'>
											<FaPencilAlt
												onClick={() => handleOpenEditModal(info)}
												className='edit text-secondary'
											/>
										</td>

										<td className='text-center mx-3'>
											<FaRegTrashAlt
												className='delet text-secondary'
												onClick={() => confimrDelete(info)}
											/>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</Row>
			{editModalProtocol ? (
				<ModalEditProtocol
					setEditModalProtocol={setEditModalProtocol}
					infoProtocolEdit={infoProtocolEdit}
				/>
			) : null}
		</>
	);
}
