import moment from "moment";
import React from "react";
import { Row, Table } from "react-bootstrap";
import 'moment/locale/es';
moment.locale('es');

function EventoContactoCDITable({ info, loadingInfo }) {
  return (
    <Row className="justify-content-around mt-1 p-3">
      <div className="headerTable">Lista de Eventos </div>
      {loadingInfo && <div className="m-auto">Cargando</div>}
      <div className="table-responsive customTables">
        <Table hover className="table-bordered m-0" size="sm">
          <thead>
            <tr>
              <th className="text-center">Fecha</th>
              <th className="text-center">Paciente</th>
              <th className="text-center">DNI</th>
              <th className="text-center">Contacto</th>
              <th className="text-center">Protocolo</th>
            </tr>
          </thead>
          <tbody>
            {info &&
              info.map((info, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center">
                        {moment.unix(info?.createDate / 1000).format("DD MMM YYYY")}
                    </td>
                    <td className="text-center">{info.user?.surname} {info.user?.name}</td>
                    <td className="text-center">{info.user?.dni}</td>
                    <td className="text-center">{info.user?.phone}</td>
                    <td className="text-center"> {info.protocol?.name}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Row>
  );
}

export default EventoContactoCDITable;
