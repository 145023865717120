import React from "react";
import NewUserSiteTable from "./NewUserSiteTable";
import "../../Styles/Modal.css";

export default function ModalCDI({ setOpenModalNewUser, newUserTipe }) {
  return (
    <div className="Modal">
      <div className="Modal__Container">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Nuevo {newUserTipe}
              </h5>
              <button
                onClick={() => setOpenModalNewUser(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <NewUserSiteTable
                newUserTipe={newUserTipe}
                setOpenModalNewUser={setOpenModalNewUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
