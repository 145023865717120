import { React, useState, useRef } from 'react'
import { FaArrowCircleDown } from "react-icons/fa";

function PointDown({reff}) {
    const [scrollShow, setScrollShow] = useState(true)


    const handleScroll = () => {
        const scroll = window.scrollY < 200
        scroll?setScrollShow(true):setScrollShow(false)
    }
    window.addEventListener('scroll', handleScroll, true);
    const handleClick = () => {
        reff.current.scrollIntoView()
    }
    
    return (
        scrollShow?<FaArrowCircleDown style={{"color":"#455B5A "}} className="point-down" onClick={handleClick}/>:<></>
    )
}

export default PointDown
