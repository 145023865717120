import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

import "../Styles/LoginRoute.css";
import "../Styles/CommonStyles.css";

export default function LoginRoute() {
  const [validated, setValidated] = useState(false);
  const [inputLogin, setInputLogin] = useState({
    email: "",
    password: "",
  });

  const { login, loading, setLoading } = useAuth();

  useEffect(() => {}, [inputLogin]);

  const handleChange = (event) => {
    setInputLogin({
      ...inputLogin,
      [event.target.name]: event.target.value,
    });
  };

  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      inputLogin.email === "" ||
      inputLogin.password === "" ||
      inputLogin.password >= 6
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      event.preventDefault();
      setLoading(true);
      await login(inputLogin.email, inputLogin.password);
    }
  }

  return (
    <Container className='blur-l'>
      <Row className='justify-content-center align-items-center min-vh-100'>
        <Col xs={10}>
          <div className='card-login o-hidden border-0 my-5'>
            <div className='card-body-login p-0'>
              <Row className='same-height-sing'>
                <Col lg={6} className='d-lg-block bg-login-image'></Col>
                <Col lg={6}>
                  <div className='p-5'>
                    <div className='text-center'>
                      <h1 className='h4 text-gray-900 mb-4'>
                        ¡Bienvenido a <b>FollowMed!</b>
                      </h1>
                    </div>
                    <div>
                      <Form
                        noValidate
                        validated={validated}
                        className='h-100'
                        onSubmit={handleSubmit}
                      >
                        <Form.Group
                          className='mt-2 w-100'
                          controlId='validateLoginEmail'
                        >
                          <Form.Control
                            onChange={handleChange}
                            type='email'
                            placeholder='Ingresar email'
                            name='email'
                            className='inputCommon'
                            required
                          />
                          <Form.Control.Feedback>
                            Se ve bien!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type='invalid'>
                            Por Favor complete el campo correctamente
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className='my-3 w-100'
                          controlId='validateLoginPassword'
                        >
                          <Form.Control
                            onChange={handleChange}
                            type='password'
                            placeholder='Contraseña'
                            minLength='6'
                            name='password'
                            className='inputCommon'
                            required
                          />
                          <Form.Control.Feedback>
                            Se ve bien!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type='invalid'>
                            Por Favor coloque una contraseña valida
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='d-flex justify-content-around-res-log align-items-center'>
                          <Button
                            className='my-5 w-25-res-log'
                            type='submit'
                            variant='outline-primary'
                          >
                            Entrar
                          </Button>
                        </Form.Group>

                        {/* <Link to="/reset-password" className="d-flex justify-content-end">olvide mi contaseña</Link> */}
                      </Form>
                    </div>
                    <hr />
                    <div></div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
