import moment from "moment";
import React from "react";
import { Row, Table } from "react-bootstrap";

import "../../../Styles/CommonStyles.css";
import 'moment/locale/es';
moment.locale('es');

export default function EventTable({ info, loadingInfo }) {
  return (
    <>
      <Row className="justify-content-around mt-1 p-3">
        {loadingInfo ? <div className="">Cargando...</div> : null}
        <div className="headerTable">Lista de Eventos </div>
        <div className="table-responsive customTables">
          <Table hover className="table-bordered m-0" size="sm">
            <thead>
              <tr>
                <th className="text-center">Fecha</th>
                <th className="text-center">Inicio de Evento</th>
								<th className='text-center'>Fin de Evento</th>
                <th className="text-center">ID Paciente</th>
                <th className="text-center">CDI</th>
                <th className="text-center">Protocolo</th>
                <th className="text-center">Evento</th>
              </tr>
            </thead>
            <tbody>
              {info.length == 0 ? (
                <td colSpan="5" className="text-center">
                  no hay eventos registrados
                </td>
              ) : (
                info.map((info, i) => {
                  return (
                    <tr>
                      <td className='text-center'>{moment.unix(info?.createDate / 1000).format('DD MMM YYYY')}</td>
											<td className='text-center'>{moment.unix(info.date / 1000).format('DD MMM YYYY')}</td>
											<td className='text-center'>{info.endDate !== 'none'
                        ? moment.unix(info.endDate / 1000).format('DD MMM YYYY')
                        : moment.unix(info.date / 1000).format('DD MMM YYYY')
                      }</td>
                      <td className="text-center">{info.user?.number}</td>
                      <td className="text-center">{info.cdi?.name_site}</td>
                      <td className="text-center">{info.protocol?.name} - {info.protocol?.number}</td>
                      <td className="text-center">{info.eventName}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
}
