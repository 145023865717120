import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import moment from 'moment';
import Swal from 'sweetalert2';

const CDIContext = React.createContext();

export function useCDI() {
	return useContext(CDIContext);
}

export function CDIProvider({ children }) {
	const [loadingInfo, setLoadingInfo] = useState();
	const [mesajeFetch, setMesajeFetch] = useState('');
	const [cdiList, setCdiList] = useState([]);
	const [protocolList, setProtocolList] = useState([]);
	const [eventList, setEventList] = useState([]);

	let { currentUser } = useAuth();

	// const url = process.env.NODE_ENV !== "development" ? "http://vps-2265777-x.dattaweb.com/api" :"http://localhost:4000";
	const url =
		process.env.NODE_ENV !== 'development'
			? 'https://api.followmed.com.ar/api'
			: 'https://api.followmed.com.ar/api';

	const headers = {
		'Content-Type': 'application/json',
		Authorization: currentUser.token,
	};

	function getCDIs(setInfo, tipe, setAllCDI, setAllArray) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/userList`,
				{
					collection: tipe,
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				if (tipe === 'admins') {
					setCdiList(response.data.response);
					setInfo(response.data.response);
					setAllArray(response.data.response);
					return;
				}
				if (tipe === 'cdis') {
					setCdiList(response.data.response);
					setAllCDI(response.data.response);
					setAllArray(response.data.response);
					return;
				}
			})
			.catch(function (error) {
				setLoadingInfo(false);
				Swal.close();
			});
	}

	function editCDI() {
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/userList`,
				{
					collection: 'users',
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				if (response.status === 200) {
					Swal.fire({
						title: 'Exito!',
						text: 'Se creo exitosamente',
						icon: 'success',
						confirmButtonText: 'Cerrar',
					});
				}
			})
			.catch(function (error) {
				Swal.close();

				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			})
			.then(function () {
				setLoadingInfo(true);
			});
	}

	function createProtocol(inputValue, type, update) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/protocols/`,
				{
					type: type,
					name: inputValue.name,
					number: inputValue.number,
					visits: inputValue.visits,
					duration: inputValue.duration,
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				listProtocols(update);
				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				setLoadingInfo(false);
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
			});
		return;
	}

	function listProtocols(setProtocols, setAllArray) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.get(`${url}/protocols/list`, { headers })
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				setProtocols(response.data.response);
				setProtocolList(response.data.response);
				setAllArray(response.data.response);
			})
			.catch(function (error) {
				setLoadingInfo(false);
				Swal.close();
				setMesajeFetch('ah ocurrido un problema');
			})
			.then(function () {});
		return;
	}

	function editProtocol(info, id, type) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		setMesajeFetch('');
		const { name, number, visits, duration } = info;

		axios
			.post(
				`${url}/protocols/edit/${id}`,
				{
					type,
					name,
					number,
					visits,
					duration,
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				Swal.fire({
					title: 'Exito!',
					text: 'Se creo exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
			})
			.catch(function (error) {
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
				setMesajeFetch('');
			});
	}

	function assingProtocol(id, tipe) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		setMesajeFetch('');
		axios
			.post(
				`${url}/protocols/assign/${id}`,
				{
					user: currentUser.id,
					type: tipe,
					start: moment().format(),
				},
				{ headers }
			)
			.then(function (response) {
				Swal.close();
				Swal.fire({
					title: 'Exito!',
					text: 'Se edito exitosamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
				});
				setLoadingInfo(false);
			})
			.catch(function (error) {
				Swal.close();
				Swal.fire({
					title: 'Oh No!',
					text: 'Ah ocurrido un error',
					icon: 'error',
					confirmButtonText: 'Cerrar',
				});
				setMesajeFetch('');
			});
	}

	function getPatients(setPatientsList, setAllArray) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/users/userList`,
				{
					collection: 'patients',
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				setPatientsList(response.data.response);
				setAllArray(response.data.response);
			})
			.catch(function (error) {
				setLoadingInfo(false);
				Swal.close();
			});
	}

	function getEvents(setAllArray) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.get(`${url}/events/list`, { headers })
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
				let data = response.data?.response.filter((item) => {
					return item.cdi === currentUser.id;
				});

				setEventList(response.data.response);
				setAllArray(response.data.response);
			})
			.catch(function (error) {
				Swal.close();
				setLoadingInfo(false);
				setMesajeFetch('ah ocurrido un problema');
			});

		return loadingInfo;
	}

	function searchEvent(search, setInfo) {
		setLoadingInfo(true);
		Swal.fire({
			title: '',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		axios
			.post(
				`${url}/events/filter`,
				{
					event: search,
				},
				{ headers }
			)
			.then(function (response) {
				setLoadingInfo(false);
				Swal.close();
			})
			.catch(function (error) {
				setLoadingInfo(false);
				Swal.close();
				setMesajeFetch('ah ocurrido un problema');
			});

		return;
	}

	const value = {
		getCDIs,
		editCDI,
		getPatients,
		listProtocols,
		createProtocol,
		editProtocol,
		loadingInfo,
		mesajeFetch,
		assingProtocol,
		getEvents,
		searchEvent,
		cdiList,
		protocolList,
		eventList,
	};

	return <CDIContext.Provider value={value}>{children}</CDIContext.Provider>;
}
