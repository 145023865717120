import { React, useState, useEffect } from 'react';
import {
	Col,
	Container,
	Row,
	Table,
	Button,
	InputGroup,
} from 'react-bootstrap';
import {
	FaSistrix,
	FaPlusCircle,
	FaPencilAlt,
	FaRegTrashAlt,
	FaCircle,
	FaInfoCircle,
} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { useHistory } from 'react-router';
import MyModalCDI from '../MyModalCDI';
import ModalPatients from './ModalPatients';
import PaginateComponent from '../../Helpers/PaginateComponent';
import { BiSpreadsheet } from 'react-icons/bi';
import MyModalPatientsData from './MyModalPatientsData';
import ModalPatientsData from './ModalPatientsData';
import MyModalEditPatient from './MyModalEditPatient';
import ModalEditPatient from './ModalEditPatient';
import { useCDI } from '../../Context/CDIContext';
import { useAuth } from '../../Context/AuthContext';
import '../../Styles/CommonStyles.css';
import { deletePatient, getPatientList } from '../../Conections/Sites';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';

export default function PatientsCDI({ setOpenModalCDI }) {
	const { getPatients, loadingInfo } = useCDI();
	const { currentUser } = useAuth();
	const [openModal, setOpenModal] = useState(false);
	const [infoPatient, setInfoPatient] = useState([]);
	const [orderPatientName, setOrderPatientName] = useState(true);
	const [orderPatientDNI, setOrderPatientDNI] = useState(true);
	const [moreDataShow, setMoreDataShow] = useState(false);
	const [totalInfo, setTotalInfo] = useState({});
	const [editPatientShow, setEditPatientShow] = useState(false);
	const [patientEditing, setPatientEditing] = useState({});
	const [allArray, setAllArray] = useState([]);
	const [mensaje, setMensaje] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);
	const history = useHistory();

	useEffect(() => {
		Swal.fire({
			title: 'Cargando Pacientes',
			allowEscapeKey: false,
			allowOutsideClick: false,
			background: 'transparent',
			showConfirmButton: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		getPatientList(currentUser.token)
			.then((resp) => {
				const filtered = resp?.response.filter((data) => {
					return data.cdi == currentUser.id;
				});
				setInfoPatient(filtered);
				setAllArray(filtered);
				Swal.close();
			})
			.catch((err) => {
				Swal.close();
				console.log(err);
			});
	}, [updateTable]);

	const handleDetailsShow = (info) => {
		setMoreDataShow(true);
		setTotalInfo(info);
	};

	useEffect(() => {
		if (!moreDataShow) {
			setTotalInfo({});
		}
	}, [moreDataShow]);

	const handleRedirect = () => {
		history.push({
			pathname: '/nuevopaciente',
			state: { edit: false },
		});
	};

	const deleteSelectPatient = (info) => {
		Swal.fire({
			title: '¿Eliminar Paciente?',
			text: `Se eliminará el paciente ${info.name} (${info.number})!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
		}).then((res) => {
			if (res.isConfirmed) {
				Swal.fire({
					title: '',
					allowEscapeKey: false,
					allowOutsideClick: false,
					background: 'transparent',
					showConfirmButton: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				deletePatient(info.id)
					.then((resp) => {
						Swal.close();
						if (resp.success) {
							Swal.fire({
								title: 'Exito!',
								text: 'Se borro exitosamente',
								icon: 'success',
								confirmButtonText: 'Cerrar',
							}).then(() => {
								setUpdateTable((updateTable) => !updateTable);
							});
						} else {
							Swal.fire({
								title: 'Oh No!',
								text: resp.message,
								icon: 'error',
								confirmButtonText: 'Cerrar',
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	};

	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 5;
	const offset = currentPage * PER_PAGE;
	const pageCount = Math.ceil(infoPatient?.length / PER_PAGE);
	const currentPageData = infoPatient?.slice(offset, offset + PER_PAGE);

	const handleEditPatientShow = (info) => {
		setEditPatientShow(true);
		setPatientEditing(info);
	};
	const handleEditPatientClose = () => {
		setEditPatientShow(false);
		setPatientEditing({});
	};

	const search = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter(
			(item) =>
				item.dni.includes(searched) ||
				item.number.includes(searched) ||
				item.name.toLowerCase().includes(searched.toLowerCase()) ||
				item.surname.toLowerCase().includes(searched.toLowerCase())
		);
		newSearch.length == 0 ? setMensaje(true) : setMensaje(false);
		setInfoPatient(newSearch);
	};

	const handleStatusSelect = (event) => {
		event.preventDefault();
		const searched = event.target.value;
		let newSearch = allArray.filter((item) => item.status.includes(searched));
		setInfoPatient(newSearch);

		if (searched == 'no') setInfoPatient(allArray);
	};

	return (
		<div className='backgroudCommon'>
			<Container fluid>
				<Row className='text-center divider pt-4 pb-1'>
					<Col className='mb-2'>
						<h2>Pacientes</h2>
					</Col>
				</Row>
				<Row className='mt-5 align-items-center'>
					<Col xs={12} md={5}>
						<div
							className='input-group ml-5-patients'
							style={{ width: 'unset' }}
						>
							<InputGroup>
								<input
									type='text'
									className='form-control '
									placeholder='Buscar...'
									onChange={search}
								/>
								<InputGroup.Text>
									<FaSistrix />
								</InputGroup.Text>
							</InputGroup>
						</div>
					</Col>
					<Col xs={12} md={3}>
						<div className='input-group ml-5-patients'>
							<select className='custom-select' onChange={handleStatusSelect}>
								<option defaultValue value='no'>
									Estado
								</option>
								<option value='green'>Verde</option>
								<option value='yellow'>Amarillo</option>
								<option value='red'>Rojo</option>
								<option value='cyan'>Azul</option>
							</select>
						</div>
					</Col>
					<Col xs={12} md={3} className='d-flex justify-content-center'>
						<button
							className='text-center btn-padding new-patient-btn btn-darkBlue'
							onClick={handleRedirect}
						>
							Nuevo Paciente <FaPlusCircle />
						</button>
					</Col>
				</Row>
				<Row className='justify-content-around position-patient-table'>
					<div className='headerTable d-flex justify-content-between'>
						<p>Lista de Pacientes</p>
						<p className='h5'>
							<FaInfoCircle data-tip data-for='infoTip' />
							<ReactTooltip id='infoTip' place='bottom' effect='solid'>
								<li className='list-unstyled'>
									<IconContext.Provider value={{ color: 'cyan' }}>
										<FaCircle /> Paciente con datos incompletos
									</IconContext.Provider>
								</li>
								<li className='list-unstyled'>
									<IconContext.Provider value={{ color: 'green' }}>
										<FaCircle /> Paciente sin problema
									</IconContext.Provider>
								</li>
								<li className='list-unstyled'>
									<IconContext.Provider value={{ color: 'yellow' }}>
										<FaCircle /> Paciente con problemas leves
									</IconContext.Provider>
								</li>
								<li className='list-unstyled'>
									<IconContext.Provider value={{ color: 'red' }}>
										<FaCircle /> Paciente con problemas graves
									</IconContext.Provider>
								</li>
							</ReactTooltip>
						</p>
					</div>
					<div className='m-auto'>{loadingInfo ? 'Cargando' : ''}</div>
					<div className='m-auto'>{mensaje ? 'No hay resultados' : ''}</div>
					<div className='table-responsive customTables'>
						<Table hover className='table-bordered m-0' size='sm'>
							<thead>
								<tr>
									<th className='text-center' rowSpan='3' colSpan='3'>
										Nombre
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										Número
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										DNI
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										Celular
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										Protocolo
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										N Protocolo
									</th>
									<th className='text-center' rowSpan='3' colSpan='3'>
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								{infoPatient &&
									currentPageData.map((info, i) => (
										<tr key={i}>
											<td className='text-center' colSpan='3'>
												{info.surname} {info.name}
											</td>

											<td className='text-center' colSpan='3'>
												{info.number}
											</td>

											<td className='text-center' colSpan='3'>
												{info.dni}
											</td>

											<td className='text-center' colSpan='3'>
												(+{info?.phone_area}) {info.phone}
											</td>
											<td className='text-center' colSpan='3'>
												{info.protocol?.name}
											</td>

											<td className='text-center' colSpan='3'>
												{info.protocol?.number}
											</td>

											<td className='text-center' colSpan='3'>
												<IconContext.Provider value={{ color: info.status }}>
													<FaCircle />
												</IconContext.Provider>
											</td>
											<td className='text-center' colSpan='1'>
												<FaPencilAlt
													className='edit text-secondary'
													onClick={() => handleEditPatientShow(info)}
												/>
											</td>

											<td className='text-center' colSpan='1'>
												<FaRegTrashAlt
													className='delet text-secondary'
													onClick={() => deleteSelectPatient(info)}
												/>
											</td>
											<td className='text-center' colSpan='1'>
												<BiSpreadsheet
													className='text-secondary '
													style={{ cursor: 'pointer' }}
													onClick={() => handleDetailsShow(info)}
												/>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
				</Row>
				<PaginateComponent
					pageCount={pageCount}
					currentPageData={currentPageData}
					setCurrentPage={setCurrentPage}
				/>
			</Container>
			{openModal ? (
				<MyModalCDI>
					<ModalPatients
						setOpenModal={setOpenModal}
						infoPatient={infoPatient}
						listPatient={() => setUpdateTable((updateTable) => !updateTable)}
					/>
				</MyModalCDI>
			) : null}
			{moreDataShow ? (
				<MyModalPatientsData>
					<ModalPatientsData
						setMoreDataShow={setMoreDataShow}
						totalInfo={totalInfo}
						listPatient={() => setUpdateTable((updateTable) => !updateTable)}
					/>
				</MyModalPatientsData>
			) : null}
			{editPatientShow ? (
				<MyModalEditPatient>
					<ModalEditPatient
						totalInfo={patientEditing}
						handleEditPatientClose={handleEditPatientClose}
						listPatient={() => setUpdateTable((updateTable) => !updateTable)}
					/>
				</MyModalEditPatient>
			) : null}
		</div>
	);
}
