import moment from "moment";
import { React, useEffect, useState } from "react";
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { editDateCalendar, getPatient } from "../../Conections/Sites";
import { FaPencilAlt } from "react-icons/fa";
import Swal from "sweetalert2";

export default function ModalTurn({ editModalClose, editingTurn }) {
  const [controlDate, setControlDate] = useState(false);
  const [newDate, setNewDate] = useState();
  const [visits, setVisits] = useState();

  useEffect(() => {
    let counter = 0;
    for (const date of editingTurn.calendarPatient) {
      if (date._seconds > editingTurn.date._seconds) counter++;
    }
    setVisits(counter);
  }, []);

  const handleDate = () => {
    setControlDate((controlDate) => !controlDate);
  };

  const editDate = () => {
    const newDateCalendar = new Date(newDate).valueOf();
    Swal.fire({
      title: "",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    editDateCalendar(
      editingTurn.id,
      editingTurn.patient.id,
      newDateCalendar,
      editingTurn.name
    ).then((res) => {
      Swal.close();
      if (res.success) {
        Swal.fire({
          title: "Exito!",
          text: "Se editó exitosamente",
          icon: "success",
          confirmButtonText: "Cerrar",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Oh No!",
          text: "Ah ocurrido un error",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    });
  };

  return (
    <div className='Modal'>
      <div className='modal-container-turn'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>Editar fecha del turno</h3>
              <button
                onClick={editModalClose}
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <Form>
                <div className='d-flex justify-content-between'>
                  <Form.Group className='w-45'>
                    <Form.Label>
                      Paciente: {editingTurn.patient?.number}
                    </Form.Label>
                    <Form.Control
                      className='inputCommon'
                      defaultValue={editingTurn.patient?.name}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className='w-45'>
                    <Form.Label>Protocolo</Form.Label>
                    <Form.Control
                      className='inputCommon'
                      readOnly
                      defaultValue={editingTurn.protocol}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <Form.Label>Visitas restantes</Form.Label>
                <Row>
                  <Form.Group as={Col} xs={3}>
                    <Form.Control
                      className='inputCommon'
                      readOnly
                      defaultValue={visits}
                    />
                  </Form.Group>
                </Row>
                <label>Dia del turno</label>
                <Row>
                  <InputGroup as={Col} xs={6}>
                    <Form.Control
                      className='inputCommon'
                      readOnly
                      aria-describedby='edit'
                      defaultValue={moment(editingTurn.date).format(
                        "DD/MM/YYYY"
                      )}
                    />
                    <Button
                      variant='outline-secondary'
                      id='edit'
                      onClick={handleDate}
                    >
                      <FaPencilAlt />
                    </Button>
                  </InputGroup>
                </Row>
                {controlDate ? (
                  <Row className='mt-2'>
                    <Col xs={6}>
                      <Row>
                        <InputGroup as={Col} xs={12}>
                          <Form.Control
                            className='inputCommon'
                            type='date'
                            onChange={(event) => setNewDate(event.target.value)}
                          />
                        </InputGroup>
                        <InputGroup as={Col} xs={12}>
                          <Button
                            className='btn-block'
                            variant='outline-secondary'
                            onClick={editDate}
                          >
                            Cambiar Turno
                          </Button>
                        </InputGroup>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                <div className='w-100 d-flex justify-content-end'>
                  <button
                    className='text-center w-25-res-agenda my-3 btn-darkBlue p-2'
                    onClick={editModalClose}
                  >
                    cerrar
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
