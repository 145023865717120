import React, { useEffect, useState } from 'react';
import MainNavbar from '../Components/MainNavbar';
import NavBarCDI from '../ComponentsCDI/NavBar/NavBarCDI';
import Pacientes from '../Components/PacientesComponents/Pacientes';
import Home from '../Components/Home/Home';
import Eventos from '../Components/Eventos/Eventos';
import Usuarios from '../Components/Users/Usuarios';
import HomeCDI from '../ComponentsCDI/Home/HomeCDI';
import { Switch, Route } from 'react-router-dom';
import PatientsCDI from '../ComponentsCDI/Patients/PatientsCDI';
import NewPatient from '../ComponentsCDI/Patients/NewPatient';
import CalendarCDI from '../ComponentsCDI/Calendar/Agenda';
import ProfileCDI from '../ComponentsCDI/Profile/ProfileCDI';
import NewUserSiteTable from '../Components/Users/NewUserSiteTable';
import EventosCDI from '../ComponentsCDI/EventsCDI/EventosCDI';

export default function DashboardRoute() {
	const [userType, setUserType] = useState('');
	useEffect(() => {
		setUserType(JSON.parse(localStorage.getItem('step_med_user')) || null);
	}, []);

	const renderSwitch = () => {
		const userLogedType = userType.type;
		if (userLogedType === 'admin' || userLogedType === 'superAdmin') {
			return (
				<>
					<MainNavbar />
					<Switch>
						<Route exact path={`/`} component={Home} />
						<Route exact path={`/pacientes`} component={Pacientes} />
						<Route exact path={`/eventos`} component={Eventos} />
						<Route exact path={`/usuarios`} component={Usuarios} />
						<Route exact path={`/newadmin`} component={NewUserSiteTable} />
						<Route path={`*`}>pagina no encontrada </Route>
					</Switch>
				</>
			);
		}
		if (userLogedType === 'cdi') {
			return (
				<>
					<NavBarCDI />
					<Switch>
						<Route exact path={`/pacientescdi`} component={PatientsCDI} />
						<Route exact path={`/nuevopaciente`} component={NewPatient} />
						<Route exact path={`/calendarcdi`} component={CalendarCDI} />
						<Route exact path={`/eventoscdi`} component={EventosCDI} />
						<Route exact path={`/perfilcdi`} component={ProfileCDI} />
						<Route exact path={`/`} component={HomeCDI} />
						<Route path={`*`}>pagina no encontrada</Route>
					</Switch>
				</>
			);
		}
	};

	return <>{renderSwitch()}</>;
}
