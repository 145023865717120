import React, { useEffect, useState } from "react";
import { Form, Col, Row, Button, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { asingProtocolToSite } from "../Conections/Sites";
import { useCDI } from "../Context/CDIContext";
import "../Styles/CommonStyles.css";

export default function AssingProtocol({ user, token }) {
  const [protocolValue, setProtocolValue] = useState({});
  const [allProtocols, setAllProtocols] = useState([]);
  const [protocols, setProtocols] = useState(user?.protocol);
  const { listProtocols } = useCDI();

  const handleChange = (event) => {
    let input = event.target.value;
    let sep = input.split("~~");
    let n_protocol = sep[0];
    let name = sep[1];

    setProtocolValue({
      ...protocolValue,
      n_protocol,
      name,
    });
  };

  const asignarProtocol = (event) => {
    event.preventDefault();
    const { id } = user;
    Swal.fire({
      title: "",
      allowEscapeKey: false,
      allowOutsideClick: false,
      background: "transparent",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    asingProtocolToSite({ token, id }, protocolValue.n_protocol)
      .then((resp) => {
        setProtocols(resp?.user?.protocol);
        Swal.close();
        if (resp.success) {
          Swal.fire({
            title: "Exito!",
            text: "Se asignó exitosamente",
            icon: "success",
            confirmButtonText: "Cerrar",
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            title: "Oh No!",
            text: resp.message,
            icon: "error",
            confirmButtonText: "Cerrar",
          });
        }
      })
      .catch((err) => {
        Swal.close();
      });
  };
  useEffect(() => {
    setProtocols(user?.protocol);
  }, [user]);
  useEffect(() => {
    listProtocols(setAllProtocols);
  }, []);
  return (
    <>
      {allProtocols.length === 0 ? (
        <div className="ml-3">no hay protocolos activos</div>
      ) : (
        <>
          <Form.Group as={Col} xs="12" md="8" controlId="slectProtocol">
            <Form.Control as="select" onChange={handleChange}>
              <option value="">Seleccione uno</option>
              {allProtocols?.map((prot, i) => {
                if (prot.number !== user.protocol[i]?.number) {
                  return(<option key={i} value={prot.n_protocol + "~~" + prot.name}>
                    {prot.name}
                  </option>)
                }
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} xs="12" md="2">
            <Button onClick={asignarProtocol}>Agregar</Button>
          </Form.Group>

          {protocols?.length >= 0
            ? protocols.map((prot, i) => {
                return (
                  <div className="w-100" key={i}>
                    <Row>
                      <Col xs="8">
                        <Card body>
                          {prot.name} {prot.number}
                        </Card>
                      </Col>
                    </Row>
                  </div>
                );
              })
            : "No tienes protocolos asignados"}
        </>
      )}
    </>
  );
}
