import React from "react";
import { Container } from "react-bootstrap";
import ResumePEP from "./HomeComponents/ResumePEP";
import HomeBody from "./HomeComponents/HomeBody";

export default function Home() {
  return (
    <>
      <Container className="backgroudCommon" fluid >
        <ResumePEP />
        <HomeBody />
      </Container>
    </>
  );
}
