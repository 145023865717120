import React, { useState, useEffect } from "react";
import moment from "moment";
import { useAuth } from "../../Context/AuthContext";
import { getCalendarSite } from "../../Conections/Sites";
import { FaCalendarCheck } from "react-icons/fa";
import Days from "./Vistas/Days";
import MyModalTurn from "./MyModalTurn";
import ModalTurn from "./ModalTurn";

import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "../../Styles/CDICalendar.css";
import "../../Styles/ResumePEP.css";
import { icons } from "react-icons/lib";

export default function Agenda() {
  const { currentUser } = useAuth();

  require("moment/locale/es"); // this is important for traduction purpose
  const [vista, setVista] = useState(5);
  const [editTurnShow, setEditTurnShow] = useState(false);
  const [editingTurn, setEditingTurn] = useState({});
  const [calendarData, setCalendarData] = useState([]);
  const [month, setMonth] = useState(moment());
  const [year, setYear] = useState(moment());

  // cambio de vistas para la tabla, solo son buleanos que ejecutan un componente o otro, las vistas tienen un componentes
  // para cada una, ya me dolia la cabeza de hacer la agenda y no se me ocurrio una mejor forma para cambiar de vista

  useEffect(() => {
    getCalendarSite(currentUser.id)
      .then((resp) => {
        setCalendarData(resp?.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const editModalShow = (turn) => {
    let calendarUser = [];
    for (const calendar of calendarData) {
      if (calendar.patient?.id == turn.patient?.id) 
        calendarUser.push(calendar.date)
    }
    turn.calendarPatient = calendarUser
    setEditingTurn(turn);
    setEditTurnShow(true);
  };
  const editModalClose = () => {
    setEditingTurn({});
    setEditTurnShow(false);
  };

  const subMonth = () => {
    setMonth(moment(month).subtract(1, "M"))

  }

  const addMonth = () => {
    setMonth(moment(month).add(1, "M"))
  }
  const subYear = () => {
    setYear(moment(year).subtract(1, "y"))
  }

  const addYear = () => {
    setYear(moment(year).add(1, "y"))
  }

  const goToday = () => {
    setYear(moment());
    setMonth(moment());
  }

  return (
    <div className=" h-100 flex-grow-1 calendar-border d-flex flex-column">
      <div className="w-100 d-flex justify-content-around align-items-center calendar-nav-border">
        <div className="w-25">
          <div className="vistas-btn p-agenda d-flex">
            <div>
              <h4>
                <i>
                  <FaChevronLeft
                    style={{
                      "font-size": "1rem",
                      cursor: "pointer",
                      color: "#31E8C5",
                    }}
                    className=""
                    onClick={subMonth}
                  />
                  <b>{month.format("MMMM")}</b>
                  <FaChevronRight
                    style={{
                      "font-size": "1rem",
                      cursor: "pointer",
                      color: "#31E8C5",
                    }}
                    className=""
                    onClick={addMonth}
                  />
                </i>
              </h4>
            </div>
            <div>
              <h4>
                <i>
                  <FaChevronLeft
                    style={{ "font-size": "1rem", cursor: "pointer" }}
                    className="text-info"
                    onClick={subYear}
                  />
                  <b>{year.format("YYYY")}</b>
                  <FaChevronRight
                    style={{ "font-size": "1rem", cursor: "pointer" }}
                    className="text-info"
                    onClick={addYear}
                  />
                </i>
              </h4>
            </div>
            <div className="ml-3 d-flex align-self-center">
              <h5>
              <FaCalendarCheck
                style={{ cursor: "pointer" }}
                onClick={goToday}
              />
              </h5>
            </div>
          </div>
        </div>
        <div className="w-75 d-flex justify-content-center">
          <div className="vistas-btn d-flex align-items-center justify-content-center w-60 py-2 my-2">
            <div className="d-flex justify-content-center btn-vistas">
              <button className="p-2  btn-darkBlue" onClick={() => setVista(1)}>
                Ver 1 dia de la agenda
              </button>
            </div>
            <div className="d-flex justify-content-center btn-vistas">
              <button className="p-2 btn-darkBlue" onClick={() => setVista(5)}>
                Ver 5 dias de la agenda
              </button>
            </div>
            <div className="d-flex justify-content-center btn-vistas">
              <button className="p-2 btn-darkBlue" onClick={() => setVista(31)}>
                Ver 30 dias de la agenda
              </button>
            </div>
          </div>
        </div>
      </div>

      <Days res={calendarData} vista={vista} editModalShow={editModalShow} month={month} year={year}/>

      {editTurnShow && (
        <MyModalTurn>
          <ModalTurn
            editModalClose={editModalClose}
            editingTurn={editingTurn}
          />
        </MyModalTurn>
      )}
    </div>
  );
}
