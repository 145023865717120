import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../../Styles/ModalCDI.css";
import "../../Styles/CommonStyles.css";

export default function ModalCDI({ setOpenModal, infoPatient }) {
  return (
    <div className="Modal">
      <div className="Modal__Container">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Paciente
              </h5>
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6>Datos del Paciente</h6>
              <Row className="justify-content-around">
                <Col xs={8}>
                  <Row className="justify-content-around">
                    <Col className="containterInfo" xs={12} md={3}>
                      <label>nombre</label>
                      <p className="infoPatient">{infoPatient.name}</p>
                    </Col>
                    <Col className="containterInfo" xs={12} md={3}>
                      <label>dni</label>
                      <p className="infoPatient">{infoPatient.dni}</p>
                    </Col>
                    <Col className="containterInfo" xs={12} md={3}>
                      <label>celular</label>
                      <p className="infoPatient">{infoPatient.phone}</p>
                    </Col>
                    <Col className="containterInfo" xs={12} md={6}>
                      <label>direccion</label>
                      <p className="infoPatient">{infoPatient.adress}</p>
                    </Col>
                    <Col className="containterInfo" xs={12} md={4}>
                      <label>email</label>
                      <p className="infoPatient">{infoPatient.email}</p>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={3}>
                  <Row>
                    <Col className="containterInfo" xs={12}>
                      <label>N Protocolo</label>
                      <p className="infoPatient">{infoPatient.id_protocol}</p>
                    </Col>
                    <Col className="containterInfo" xs={12}>
                      <label>protocolo</label>
                      <p className="infoPatient">{infoPatient.protocol[0]}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <h6>Turnos Asignados</h6>
              <Row className="justify-content-around">
                <Col xs={12}>
                  <Row className="justify-content-around">
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                    <Col className="containterTurns" xs={12} md={3}>
                      <p className="">12/03/21</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <h6>Eventos Ocurridos</h6>
              <Row className="justify-content-around my-5">
                <Col xs={12}>
                  <Row>
                    <Col xs={3}>
                      <div className="eventsLine">2</div>
                    </Col>
                    <Col xs={9}>
                      <div className="eventsDate">14 de septiembre</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <div className="eventsLine">2</div>
                    </Col>
                    <Col xs={9}>
                      <div className="eventsDate">14 de septiembre</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <h6>Status del Paciente</h6>
              <Row className="justify-content-around my-5">
                <Col xs={5}>
                  <div className="eventsLine">Fecha: 12/03/21</div>
                </Col>
                <Col xs={7}>
                  <div className="eventsDate">
                    Sin eventos del ultimo control Estado: Bien
                  </div>
                </Col>
              </Row>
              <h6>Observaciones</h6>
              <Row className="justify-content-around my-5">
                <Col xs={12}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="btnCommon btn-block btn"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
