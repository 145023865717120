import { React, useState } from "react";
import CDIDetails from "./HomeComponents/CDIDetails";
import { Button } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import "../../Styles/Modal.css";

export default function ModalCDI({
  setOpenModalCDIDetails,
  details,
  setAllArray,
  setInfo,
  getCDIs,
}) {
  const [readOnly, setReadOnly] = useState(true);
  const enableEdit = () => {
    readOnly ? setReadOnly(false) : setReadOnly(true);
  };
  return (
    <div className='Modal'>
      <div className='Modal__Container'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Detalles del Site
              </h5>
              <div className='d-flex align-items-center'>
                {readOnly ? (
                  <Button
                    variant='outline-warning'
                    className='py-1'
                    onClick={enableEdit}
                  >
                    <FaPencilAlt />
                  </Button>
                ) : (
                  <Button
                    variant='outline-danger'
                    className='py-1'
                    onClick={enableEdit}
                  >
                    Cancelar edición
                  </Button>
                )}

                <button
                  onClick={() => setOpenModalCDIDetails(false)}
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
            </div>
            <div className='modal-body'>
              <CDIDetails
                details={details}
                readOnly={readOnly}
                setAllArray={setAllArray}
                setInfo={setInfo}
                getCDIs={getCDIs}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
